<template>
  <q-card flat borderedclass="q-pa-md full-height full-width">
    <q-card-section>
      <p>
        <a href="https://www.ilect.net/">iLect CL</a>
        {{ $t("views.about.description") }}
      </p>
    </q-card-section>

    <q-separator inset />

    <q-card-section>
      <p>
        <router-link to="privacy-policy">
          {{ $t("views.about.privacy") }}
        </router-link>
      </p>
    </q-card-section>
  </q-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AboutView",
});
</script>
