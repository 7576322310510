import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Login from "../views/Login.vue";
import SetPassword from "../views/SetPassword.vue";
import About from "../views/About.vue";
import NotFound from "../views/NotFound.vue";
import { AuthModule } from "@/store/modules/auth";
import { BreadcrumbModule } from "@/store/modules/breadcrumbs";
import {
  assessmentTableBreadcrumbGenerator,
  assessmentDetailBreadcrumbGenerator,
  assignmentBreadcrumbGenerator,
  assignmentDetailBreadcrumbGenerator,
  Breadcrumb,
  breadcrumbsUntilCourseDetail,
  courseForumBreadcrumbGenerator,
  submissionBreadcrumbGenerator,
  videoListBreadcrumbGenerator,
} from "@/router/breadcrumbs";
import { assessmentAnalysisApi } from "@/api/ilect-openapi-configuration";
import PasswordChange from "@/views/PasswordChange.vue";
import { RouteLocationNormalizedLoaded } from "vue-router";

// Type for route record is exported in @types

const routes: RouteRecordRaw[] = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "iLect-CL Login",
      breadcrumbGenerator: undefined,
      requiresAuth: undefined,
    },
  },

  {
    path: "/set-password",
    name: "SetPassword",
    component: SetPassword,
    meta: {
      title: "iLect-CL Set Password",
    },
  },

  {
    path: "/password-change",
    name: "PasswordChange",
    component: PasswordChange,
    meta: {
      title: "Change Password",
    },
  },

  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      title: "iLect-CL About",
    },
  },

  {
    path: "/testtype",
    name: "TestType",
    component: () =>
      import(/* webpackChunkName: "testtype" */ "../views/TestType.vue"),
    meta: {
      title: "iLect-CL TestType",
      requiresAuth: true,
    },
  },

  {
    path: "/profile/:userId?",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
    meta: {
      title: "iLect-CL Profile",
      requiresAuth: true,
    },
  },

  {
    path: "/course",
    name: "Course",
    component: () =>
      import(/* webpackChunkName: "course" */ "../views/Course.vue"),
    meta: {
      title: "iLect-CL Course",
      requiresAuth: true,
    },
  },

  {
    path: "/course/:course_id/workbook",
    name: "Workbook",
    component: () =>
      import(/* webpackChunkName: "workbook" */ "../views/Workbook.vue"),
    meta: {
      title: "iLect Workbook",
      requiresAuth: true,
      breadcrumbGenerator: () => {
        return [
          ...breadcrumbsUntilCourseDetail(),
          {
            label: "Workbook",
            to: null,
            icon: "fas fa-book-open",
          },
        ];
      },
    },
  },

  {
    path: "/course/:course_id/calendar",
    name: "Calendar",
    component: () =>
      import(/* webpackChunkName: "calendar" */ "../views/Calendar.vue"),
    meta: {
      title: "Calendar",
      requiresAuth: true,
    },
  },

  {
    path: "/course/:course_id/assignment",
    name: "Assignment",
    component: () =>
      import(/* webpackChunkName: "assignment" */ "../views/Assignment.vue"),
    meta: {
      title: "iLect-CL Assignment",
      requiresAuth: true,
      breadcrumbGenerator: () => {
        return [
          ...breadcrumbsUntilCourseDetail(),
          assignmentBreadcrumbGenerator(),
        ];
      },
    },
  },

  {
    path: "/course/:course_id/assessment",
    name: "Assessment",
    beforeEnter: (to, from, next) => {
      const courseId = to.params.course_id as string;
      const userId = AuthModule.userId;
      assessmentAnalysisApi
        .assessmentAnalysisExistenceCourseRetrieve(courseId)
        .then((res) => {
          // if no assessment data exists do not forward request
          if (!res.data) {
            return;
          }
          if (AuthModule.isStaff() || AuthModule.isEditor(courseId)) {
            next();
          } else {
            next({
              name: "AssessmentDetail",
              params: { course_id: courseId, user_id: userId },
            });
          }
        })
        .catch((err: unknown) => {
          console.error(err);
        });
    },
    component: () =>
      import(/* webpackChunkName: "assignment" */ "../views/Assessment.vue"),
    meta: {
      title: "iLect-CL Assessment",
      requiresAuth: true,
      breadcrumbGenerator: () => {
        return [
          ...breadcrumbsUntilCourseDetail(),
          assessmentTableBreadcrumbGenerator(),
        ];
      },
    },
  },
  {
    path: "/course/:course_id/assessment/:user_id/",
    name: "AssessmentDetail",
    beforeEnter: (to, from, next) => {
      const courseId = to.params.course_id as string;

      // check if assessment data is available
      assessmentAnalysisApi
        .assessmentAnalysisExistenceCourseRetrieve(courseId)
        .then((res) => {
          // if no assessment data exists do not forward request
          if (!res.data) {
            return;
          }
          next();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    component: () =>
      import(
        /* webpackChunkName: "assignment" */ "../views/AssessmentDetail.vue"
      ),
    meta: {
      title: "iLect-CL Assessment Detail",
      requiresAuth: true,
      breadcrumbGenerator: () => {
        return [
          ...breadcrumbsUntilCourseDetail(),
          assessmentTableBreadcrumbGenerator(),
          assessmentDetailBreadcrumbGenerator(),
        ];
      },
    },
  },

  {
    path: "/course/:course_id/assignment/:assignment_id",
    name: "AssignmentDetail",
    component: () =>
      import(
        /* webpackChunkName: "assignment" */ "../views/AssignmentDetail.vue"
      ),
    meta: {
      title: "iLect-CL Assignment",
      requiresAuth: true,
      breadcrumbGenerator: () => {
        const assignmentDetailBreadcrumb: Breadcrumb = {
          label: BreadcrumbModule.getAssignmentName(),
          icon: "",
        };
        return [
          ...breadcrumbsUntilCourseDetail(),
          assignmentBreadcrumbGenerator(),
          assignmentDetailBreadcrumb,
        ];
      },
    },
  },

  {
    path: "/course/:course_id/quiz/:quiz_id",
    name: "QuizDetail",
    component: () =>
      import(/* webpackChunkName: "quiz" */ "../views/QuizDetail.vue"),
    meta: {
      title: "iLect-CL Quiz",
      requiresAuth: true,
      breadcrumbGenerator: () => {
        const quizDetailBreadcrumb: Breadcrumb = {
          label: BreadcrumbModule.getQuizName(),
          icon: "",
        };
        return [...breadcrumbsUntilCourseDetail(), quizDetailBreadcrumb];
      },
    },
  },

  {
    path: "/course/:course_id/submission",
    name: "Submission",
    component: () =>
      import(/* webpackChunkName: "submission" */ "../views/Submission.vue"),
    meta: {
      title: "iLect-CL Submission",
      requiresAuth: true,
      breadcrumbGenerator: () => {
        return [
          ...breadcrumbsUntilCourseDetail(),
          submissionBreadcrumbGenerator(),
        ];
      },
    },
  },

  {
    path: "/course/:course_id/submission/:submission_id",
    name: "SubmissionDetail",
    component: () =>
      import(
        /* webpackChunkName: "submission" */ "../views/SubmissionDetail.vue"
      ),
    meta: {
      title: "iLect-CL Submission",
      requiresAuth: true,
      breadcrumbGenerator: () => {
        return [
          ...breadcrumbsUntilCourseDetail(),
          submissionBreadcrumbGenerator(),
          {
            label: BreadcrumbModule.getSubmissionName(),
            icon: "",
          },
        ];
      },
    },
  },

  {
    path: "/course/:course_id/forum",
    name: "Forum",
    component: () =>
      import(/* webpackChunkName: "submission" */ "../views/CourseForum.vue"),
    meta: {
      title: "Course Forum",
      requiresAuth: true,
      breadcrumbGenerator: () => {
        return [
          ...breadcrumbsUntilCourseDetail(),
          courseForumBreadcrumbGenerator(),
        ];
      },
    },
  },

  {
    path: "/course/:course_id/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
    meta: {
      title: "iLect-CL Settings",
      requiresAuth: true,
      breadcrumbGenerator: () => {
        return [
          ...breadcrumbsUntilCourseDetail(),
          {
            label: "Settings",
            to: null,
            icon: "settings",
          },
        ];
      },
    },
  },

  {
    path: "/course/:course_id/testcase/:assignment_id",
    name: "TestCase",
    component: () =>
      import(/* webpackChunkName: "testcase" */ "../views/TestCase.vue"),
    meta: {
      title: "iLect-CL TestCase",
      requiresAuth: true,
      breadcrumbGenerator: () => {
        return [
          ...breadcrumbsUntilCourseDetail(),
          assignmentBreadcrumbGenerator(),
          assignmentDetailBreadcrumbGenerator(),
          {
            label: "Testcase",
            icon: "",
          },
        ];
      },
    },
  },

  {
    path: "/course/:course_id/forum/:forum_record_id",
    name: "ForumDetailInCourse",
    component: () =>
      import(/* webpackChunkName: "forumDetail" */ "../views/ForumDetail.vue"),
    meta: {
      title: "iLect-CL Forum",
      requiresAuth: true,
      breadcrumbGenerator: () => {
        return [
          ...breadcrumbsUntilCourseDetail(),
          courseForumBreadcrumbGenerator(),
          {
            label: BreadcrumbModule.getForumTitle(),
            icon: "",
          },
        ];
      },
    },
  },

  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "privacyPolicy" */ "../views/PrivacyPolicy.vue"
      ),
    meta: {
      title: "iLect-CL Privacy Policy",
    },
  },

  {
    path: "/",
    name: "Home",
    redirect: "Course",
  },

  {
    path: "/pygrade/",
    name: "Pygrade",
    meta: {
      title: "Pygrade",
    },
    beforeEnter: (to, from, next) => {
      const origin = window.location.origin;
      const newURL = origin + "/pygrade/";
      window.location.href = newURL;
      next(false);
    },
  },

  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "iLect-CL Not Found",
      requiresAuth: true,
    },
  },

  {
    path: "/course/:course_id/video",
    name: "Video",
    component: () =>
      import(/* webpackChunkName: "video" */ "../views/VideoComponent.vue"),
    meta: {
      title: "Video",
      requiresAuth: true,
      breadcrumbGenerator: () => [
        ...breadcrumbsUntilCourseDetail(),
        videoListBreadcrumbGenerator(),
      ],
    },
  },
  {
    path: "/course/:course_id/:platform/:video_url",
    name: "VideoDetail",
    component: () =>
      import(/* webpackChunkName: "videoDetails" */ "../views/VideoDetail.vue"),
    meta: {
      title: "Video Detail",
      requiresAuth: true,
      breadcrumbGenerator: (route: RouteLocationNormalizedLoaded) => {
        console.log(route);
        return [
          ...breadcrumbsUntilCourseDetail(),
          videoListBreadcrumbGenerator(),
          {
            label: BreadcrumbModule.getVideoTitle(),
            icon: "",
          },
        ];
      },
    },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
});

// Check whether a requested page requires authentications or not, if it does
// then check whether a user is authenticated or not.
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.meta.requiresAuth && !AuthModule.isAuthenticated) {
    AuthModule.setCallback(to);
    next("/login");
  } else {
    next();
  }
});

export default router;
