/* tslint:disable */
/* eslint-disable */
/**
 * iLect
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Answer
 */
export interface Answer {
    /**
     * 
     * @type {string}
     * @memberof Answer
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Answer
     */
    'problem': string;
    /**
     * 
     * @type {string}
     * @memberof Answer
     */
    'code'?: string | null;
}
/**
 * This serializer is just for type compatibility in OpenAPI since we are returning custom Response in \"AssessmentAnalysisDetail\" view, the OpenAPI is not able to recognize correct type for that view
 * @export
 * @interface AssessmentAnalysisDetailAPIResponse
 */
export interface AssessmentAnalysisDetailAPIResponse {
    /**
     * 
     * @type {Array<AssessmentAnalysisSerializerDetail>}
     * @memberof AssessmentAnalysisDetailAPIResponse
     */
    'skills': Array<AssessmentAnalysisSerializerDetail>;
    /**
     * 
     * @type {string}
     * @memberof AssessmentAnalysisDetailAPIResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentAnalysisDetailAPIResponse
     */
    'certification': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentAnalysisDetailAPIResponse
     */
    'technical_grade': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentAnalysisDetailAPIResponse
     */
    'soft_grade': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentAnalysisDetailAPIResponse
     */
    'overall_grade': string;
    /**
     * 
     * @type {number}
     * @memberof AssessmentAnalysisDetailAPIResponse
     */
    'overall_skill_score': number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentAnalysisDetailAPIResponse
     */
    'overall_skill_zscore': number;
}
/**
 * This is used as for assessment detail page serializer, although in-addition to this serializer we add additional field in using Response in view AssessmentAnalysisDetail
 * @export
 * @interface AssessmentAnalysisSerializerDetail
 */
export interface AssessmentAnalysisSerializerDetail {
    /**
     * 
     * @type {number}
     * @memberof AssessmentAnalysisSerializerDetail
     */
    'score': number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentAnalysisSerializerDetail
     */
    'skill': string;
    /**
     * 
     * @type {number}
     * @memberof AssessmentAnalysisSerializerDetail
     */
    'avg_skill_score': number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentAnalysisSerializerDetail
     */
    'rank_outof': number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentAnalysisSerializerDetail
     */
    'rank': number;
    /**
     * 
     * @type {SkillTypeEnum}
     * @memberof AssessmentAnalysisSerializerDetail
     */
    'skill_type'?: SkillTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AssessmentAnalysisSerializerDetail
     */
    'zscore': number;
}


/**
 * This is used for assessment table api
 * @export
 * @interface AssessmentAnalysisSerializerList
 */
export interface AssessmentAnalysisSerializerList {
    /**
     * 
     * @type {string}
     * @memberof AssessmentAnalysisSerializerList
     */
    'user': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentAnalysisSerializerList
     */
    'email': string;
    /**
     * 
     * @type {Array<AssessmentAnalysisSerializerSkillInfo>}
     * @memberof AssessmentAnalysisSerializerList
     */
    'skills': Array<AssessmentAnalysisSerializerSkillInfo>;
    /**
     * 
     * @type {number}
     * @memberof AssessmentAnalysisSerializerList
     */
    'overall_skill_zscore': number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentAnalysisSerializerList
     */
    'grade_label': string;
}
/**
 * This is used as nested serializer inside AssessmentAnalysisSerializerList
 * @export
 * @interface AssessmentAnalysisSerializerSkillInfo
 */
export interface AssessmentAnalysisSerializerSkillInfo {
    /**
     * 
     * @type {number}
     * @memberof AssessmentAnalysisSerializerSkillInfo
     */
    'score': number;
    /**
     * 
     * @type {string}
     * @memberof AssessmentAnalysisSerializerSkillInfo
     */
    'skill': string;
}
/**
 * 
 * @export
 * @interface AssessmentAssignmentDetail
 */
export interface AssessmentAssignmentDetail {
    /**
     * 
     * @type {string}
     * @memberof AssessmentAssignmentDetail
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentAssignmentDetail
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AssessmentAssignmentDetail
     */
    'score': number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentAssignmentDetail
     */
    'total_score': number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentAssignmentDetail
     */
    'user_rank': number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentAssignmentDetail
     */
    'total_rank': number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentAssignmentDetail
     */
    'zscore': number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentAssignmentDetail
     */
    'average': number;
}
/**
 * 
 * @export
 * @interface AssessmentAssignmentList
 */
export interface AssessmentAssignmentList {
    /**
     * 
     * @type {string}
     * @memberof AssessmentAssignmentList
     */
    'user': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentAssignmentList
     */
    'email': string;
    /**
     * 
     * @type {Array<AssessmentAssignmentListChild>}
     * @memberof AssessmentAssignmentList
     */
    'assignments': Array<AssessmentAssignmentListChild>;
}
/**
 * 
 * @export
 * @interface AssessmentAssignmentListChild
 */
export interface AssessmentAssignmentListChild {
    /**
     * 
     * @type {string}
     * @memberof AssessmentAssignmentListChild
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AssessmentAssignmentListChild
     */
    'score': number;
}
/**
 * 
 * @export
 * @interface AssessmentQuizDetail
 */
export interface AssessmentQuizDetail {
    /**
     * 
     * @type {string}
     * @memberof AssessmentQuizDetail
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AssessmentQuizDetail
     */
    'score': number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentQuizDetail
     */
    'total_score': number;
    /**
     * 
     * @type {number}
     * @memberof AssessmentQuizDetail
     */
    'average': number;
}
/**
 * 
 * @export
 * @interface AssessmentQuizList
 */
export interface AssessmentQuizList {
    /**
     * 
     * @type {string}
     * @memberof AssessmentQuizList
     */
    'user': string;
    /**
     * 
     * @type {string}
     * @memberof AssessmentQuizList
     */
    'email': string;
    /**
     * 
     * @type {Array<AssessmentQuizListChild>}
     * @memberof AssessmentQuizList
     */
    'quizzes': Array<AssessmentQuizListChild>;
}
/**
 * 
 * @export
 * @interface AssessmentQuizListChild
 */
export interface AssessmentQuizListChild {
    /**
     * 
     * @type {string}
     * @memberof AssessmentQuizListChild
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AssessmentQuizListChild
     */
    'score': number;
}
/**
 * 
 * @export
 * @interface Assignment
 */
export interface Assignment {
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Assignment
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Assignment
     */
    'descending_leaderboard'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Assignment
     */
    'leaderboard_limit'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Assignment
     */
    'courses': Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof Assignment
     */
    'created_at': Date;
    /**
     * 
     * @type {Date}
     * @memberof Assignment
     */
    'updated_at': Date;
}
/**
 * fetches assignment\'s user/course specific details (expiry, user\'s submission status)
 * @export
 * @interface AssignmentCourseSpecific
 */
export interface AssignmentCourseSpecific {
    /**
     * 
     * @type {string}
     * @memberof AssignmentCourseSpecific
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AssignmentCourseSpecific
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof AssignmentCourseSpecific
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AssignmentCourseSpecific
     */
    'descending_leaderboard'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AssignmentCourseSpecific
     */
    'leaderboard_limit'?: number;
    /**
     * 
     * @type {Date}
     * @memberof AssignmentCourseSpecific
     */
    'created_at': Date;
    /**
     * 
     * @type {Date}
     * @memberof AssignmentCourseSpecific
     */
    'updated_at': Date;
    /**
     * 
     * @type {string}
     * @memberof AssignmentCourseSpecific
     */
    'submit_token': string;
    /**
     * 
     * @type {boolean}
     * @memberof AssignmentCourseSpecific
     */
    'submitted': boolean;
    /**
     * 
     * @type {Date}
     * @memberof AssignmentCourseSpecific
     */
    'expiry_date': Date;
}
/**
 * 
 * @export
 * @interface AssignmentCourseTestCaseList
 */
export interface AssignmentCourseTestCaseList {
    /**
     * 
     * @type {string}
     * @memberof AssignmentCourseTestCaseList
     */
    'testcase_id': string;
    /**
     * 
     * @type {string}
     * @memberof AssignmentCourseTestCaseList
     */
    'testcase_title': string;
    /**
     * 
     * @type {string}
     * @memberof AssignmentCourseTestCaseList
     */
    'test_type_title': string;
    /**
     * 
     * @type {boolean}
     * @memberof AssignmentCourseTestCaseList
     */
    'show_on_leaderboard'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AssignmentCourseTestCaseList
     */
    'immediate'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AssignmentCourseTestCaseList
     */
    'assigned_score': number;
    /**
     * 
     * @type {Date}
     * @memberof AssignmentCourseTestCaseList
     */
    'updated_at': Date;
}
/**
 * 
 * @export
 * @interface AssignmentFileSubmission
 */
export interface AssignmentFileSubmission {
    /**
     * 
     * @type {string}
     * @memberof AssignmentFileSubmission
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AssignmentFileSubmission
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof AssignmentFileSubmission
     */
    'url': string;
    /**
     * 
     * @type {Date}
     * @memberof AssignmentFileSubmission
     */
    'created_at': Date;
}
/**
 * 
 * @export
 * @interface Course
 */
export interface Course {
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    'submission_interval_sec'?: number;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'gapi_sa_email': string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Course
     */
    'workbook_config'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Course
     */
    'default_duration_minutes': number;
    /**
     * 
     * @type {boolean}
     * @memberof Course
     */
    'is_gpu': boolean;
    /**
     * 
     * @type {Date}
     * @memberof Course
     */
    'created_at': Date;
    /**
     * 
     * @type {Date}
     * @memberof Course
     */
    'updated_at': Date;
    /**
     * 
     * @type {boolean}
     * @memberof Course
     */
    'active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Course
     */
    'contain_videos': boolean;
}
/**
 * 
 * @export
 * @interface CourseList
 */
export interface CourseList {
    /**
     * 
     * @type {string}
     * @memberof CourseList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CourseList
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CourseList
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseList
     */
    'workbook_config'?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CourseList
     */
    'created_at': Date;
    /**
     * 
     * @type {Date}
     * @memberof CourseList
     */
    'updated_at': Date;
    /**
     * 
     * @type {string}
     * @memberof CourseList
     */
    'created_by'?: string | null;
}
/**
 * 
 * @export
 * @interface Dataset
 */
export interface Dataset {
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'assignment': string;
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'url': string;
    /**
     * 
     * @type {Date}
     * @memberof Dataset
     */
    'updated_at': Date;
    /**
     * 
     * @type {Date}
     * @memberof Dataset
     */
    'created_at': Date;
}
/**
 * 
 * @export
 * @interface Editor
 */
export interface Editor {
    /**
     * 
     * @type {string}
     * @memberof Editor
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof Editor
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Editor
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof Editor
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof Editor
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof Editor
     */
    'is_author': boolean;
}
/**
 * 
 * @export
 * @interface Evaluation
 */
export interface Evaluation {
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    'testcase': string | null;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    'testcase_title': string;
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    'testcase_score': number;
    /**
     * 
     * @type {boolean}
     * @memberof Evaluation
     */
    'show_score': boolean;
    /**
     * 
     * @type {string}
     * @memberof Evaluation
     */
    'submission': string;
    /**
     * 
     * @type {StatusEnum}
     * @memberof Evaluation
     */
    'status'?: StatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    'score'?: number;
    /**
     * 
     * @type {Date}
     * @memberof Evaluation
     */
    'created_at': Date;
    /**
     * 
     * @type {Date}
     * @memberof Evaluation
     */
    'updated_at': Date;
}


/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'details'?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Event
     */
    'date'?: Date;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    'days'?: number;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    'bgcolor'?: string;
}
/**
 * 
 * @export
 * @interface Exam
 */
export interface Exam {
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Exam
     */
    'name': string;
    /**
     * 
     * @type {Date}
     * @memberof Exam
     */
    'start_at'?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Exam
     */
    'end_at'?: Date | null;
    /**
     * Minutes
     * @type {number}
     * @memberof Exam
     */
    'duration'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Exam
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Exam
     */
    'problems_count': number;
}
/**
 * 
 * @export
 * @interface ExamDetail
 */
export interface ExamDetail {
    /**
     * 
     * @type {string}
     * @memberof ExamDetail
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ExamDetail
     */
    'name': string;
    /**
     * 
     * @type {Date}
     * @memberof ExamDetail
     */
    'start_at'?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ExamDetail
     */
    'end_at'?: Date | null;
    /**
     * Minutes
     * @type {number}
     * @memberof ExamDetail
     */
    'duration'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ExamDetail
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<any>}
     * @memberof ExamDetail
     */
    'problems': Array<any>;
}
/**
 * 
 * @export
 * @interface Feedback
 */
export interface Feedback {
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'course_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'user_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof Feedback
     */
    'feedback': string;
}
/**
 * 
 * @export
 * @interface FileSubmission
 */
export interface FileSubmission {
    /**
     * 
     * @type {string}
     * @memberof FileSubmission
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FileSubmission
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileSubmission
     */
    'url': string;
    /**
     * 
     * @type {Date}
     * @memberof FileSubmission
     */
    'created_at': Date;
}
/**
 * 
 * @export
 * @interface ForumFileUpload
 */
export interface ForumFileUpload {
    /**
     * 
     * @type {string}
     * @memberof ForumFileUpload
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ForumFileUpload
     */
    'data': string;
    /**
     * 
     * @type {string}
     * @memberof ForumFileUpload
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof ForumFileUpload
     */
    'url': string;
    /**
     * 
     * @type {Date}
     * @memberof ForumFileUpload
     */
    'created_at': Date;
}
/**
 * 
 * @export
 * @interface ForumRecordDetailed
 */
export interface ForumRecordDetailed {
    /**
     * 
     * @type {string}
     * @memberof ForumRecordDetailed
     */
    'created_by_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ForumRecordDetailed
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ForumRecordDetailed
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ForumRecordDetailed
     */
    'content': string;
    /**
     * 
     * @type {boolean}
     * @memberof ForumRecordDetailed
     */
    'edited': boolean;
    /**
     * 
     * @type {string}
     * @memberof ForumRecordDetailed
     */
    'parent_record'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ForumRecordDetailed
     */
    'upvotes_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof ForumRecordDetailed
     */
    'is_announcement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ForumRecordDetailed
     */
    'did_upvote': boolean;
    /**
     * 
     * @type {Date}
     * @memberof ForumRecordDetailed
     */
    'created_at': Date;
    /**
     * 
     * @type {Date}
     * @memberof ForumRecordDetailed
     */
    'updated_at': Date;
    /**
     * 
     * @type {boolean}
     * @memberof ForumRecordDetailed
     */
    'is_deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ForumRecordDetailed
     */
    'created_by_username': string | null;
    /**
     * 
     * @type {string}
     * @memberof ForumRecordDetailed
     */
    'created_by_icon': string | null;
    /**
     * 
     * @type {string}
     * @memberof ForumRecordDetailed
     */
    'course'?: string | null;
    /**
     * 
     * @type {Array<ForumFileUpload>}
     * @memberof ForumRecordDetailed
     */
    'forum_file_uploads': Array<ForumFileUpload>;
    /**
     * 
     * @type {boolean}
     * @memberof ForumRecordDetailed
     */
    'pinned_status': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ForumRecordDetailed
     */
    'read_status': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ForumRecordDetailed
     */
    'is_scheduled_announcement'?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ForumRecordDetailed
     */
    'scheduled_date'?: Date | null;
}
/**
 * 
 * @export
 * @interface ForumRecordList
 */
export interface ForumRecordList {
    /**
     * 
     * @type {string}
     * @memberof ForumRecordList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ForumRecordList
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ForumRecordList
     */
    'content': string;
    /**
     * 
     * @type {boolean}
     * @memberof ForumRecordList
     */
    'edited': boolean;
    /**
     * 
     * @type {number}
     * @memberof ForumRecordList
     */
    'upvotes_count': number;
    /**
     * 
     * @type {number}
     * @memberof ForumRecordList
     */
    'comments_count': number;
    /**
     * 
     * @type {boolean}
     * @memberof ForumRecordList
     */
    'is_announcement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ForumRecordList
     */
    'is_deleted'?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ForumRecordList
     */
    'created_at': Date;
    /**
     * 
     * @type {Date}
     * @memberof ForumRecordList
     */
    'updated_at': Date;
    /**
     * 
     * @type {string}
     * @memberof ForumRecordList
     */
    'created_by'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ForumRecordList
     */
    'created_by_icon': string | null;
    /**
     * 
     * @type {string}
     * @memberof ForumRecordList
     */
    'created_by_username': string | null;
    /**
     * 
     * @type {string}
     * @memberof ForumRecordList
     */
    'course'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ForumRecordList
     */
    'pinned_status': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ForumRecordList
     */
    'read_status': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ForumRecordList
     */
    'is_scheduled_announcement'?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ForumRecordList
     */
    'scheduled_date'?: Date | null;
}
/**
 * 
 * @export
 * @interface ForumStats
 */
export interface ForumStats {
    /**
     * 
     * @type {number}
     * @memberof ForumStats
     */
    'unread_forum_count': number;
    /**
     * 
     * @type {number}
     * @memberof ForumStats
     */
    'unread_announcements_count': number;
    /**
     * 
     * @type {number}
     * @memberof ForumStats
     */
    'unread_total_count': number;
}
/**
 * 
 * @export
 * @interface ForumTemplate
 */
export interface ForumTemplate {
    /**
     * 
     * @type {number}
     * @memberof ForumTemplate
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ForumTemplate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ForumTemplate
     */
    'forum_title': string;
    /**
     * 
     * @type {string}
     * @memberof ForumTemplate
     */
    'forum_content': string;
}
/**
 * 
 * @export
 * @interface ForumUserStatus
 */
export interface ForumUserStatus {
    /**
     * 
     * @type {boolean}
     * @memberof ForumUserStatus
     */
    'pinned_status': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ForumUserStatus
     */
    'read_status': boolean;
}
/**
 * * `NOG` - No GPU * `G16` - GPU 16G * `G32` - GPU 32G
 * @export
 * @enum {string}
 */

export const GpuTypeEnum = {
    Nog: 'NOG',
    G16: 'G16',
    G32: 'G32'
} as const;

export type GpuTypeEnum = typeof GpuTypeEnum[keyof typeof GpuTypeEnum];


/**
 * 
 * @export
 * @interface Histogram
 */
export interface Histogram {
    /**
     * 
     * @type {number}
     * @memberof Histogram
     */
    'distribution_range': number;
    /**
     * 
     * @type {number}
     * @memberof Histogram
     */
    'user_count': number;
}
/**
 * Serializer for JWT authentication.
 * @export
 * @interface JWT
 */
export interface JWT {
    /**
     * 
     * @type {string}
     * @memberof JWT
     */
    'access': string;
    /**
     * 
     * @type {string}
     * @memberof JWT
     */
    'refresh': string;
    /**
     * 
     * @type {UserDetails}
     * @memberof JWT
     */
    'user': UserDetails;
}
/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface PaginatedCourseListList
 */
export interface PaginatedCourseListList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedCourseListList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedCourseListList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedCourseListList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<CourseList>}
     * @memberof PaginatedCourseListList
     */
    'results': Array<CourseList>;
}
/**
 * 
 * @export
 * @interface PaginatedDatasetList
 */
export interface PaginatedDatasetList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedDatasetList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedDatasetList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedDatasetList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Dataset>}
     * @memberof PaginatedDatasetList
     */
    'results': Array<Dataset>;
}
/**
 * 
 * @export
 * @interface PaginatedEvaluationList
 */
export interface PaginatedEvaluationList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedEvaluationList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedEvaluationList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedEvaluationList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Evaluation>}
     * @memberof PaginatedEvaluationList
     */
    'results': Array<Evaluation>;
}
/**
 * 
 * @export
 * @interface PaginatedExamList
 */
export interface PaginatedExamList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedExamList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedExamList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedExamList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Exam>}
     * @memberof PaginatedExamList
     */
    'results': Array<Exam>;
}
/**
 * 
 * @export
 * @interface PaginatedForumRecordDetailedList
 */
export interface PaginatedForumRecordDetailedList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedForumRecordDetailedList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedForumRecordDetailedList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedForumRecordDetailedList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<ForumRecordDetailed>}
     * @memberof PaginatedForumRecordDetailedList
     */
    'results': Array<ForumRecordDetailed>;
}
/**
 * 
 * @export
 * @interface PaginatedForumRecordListList
 */
export interface PaginatedForumRecordListList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedForumRecordListList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedForumRecordListList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedForumRecordListList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<ForumRecordList>}
     * @memberof PaginatedForumRecordListList
     */
    'results': Array<ForumRecordList>;
}
/**
 * 
 * @export
 * @interface PaginatedProblemList
 */
export interface PaginatedProblemList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedProblemList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedProblemList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedProblemList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Problem>}
     * @memberof PaginatedProblemList
     */
    'results': Array<Problem>;
}
/**
 * 
 * @export
 * @interface PaginatedSubmissionListList
 */
export interface PaginatedSubmissionListList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedSubmissionListList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSubmissionListList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedSubmissionListList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<SubmissionList>}
     * @memberof PaginatedSubmissionListList
     */
    'results': Array<SubmissionList>;
}
/**
 * 
 * @export
 * @interface PaginatedTrainingList
 */
export interface PaginatedTrainingList {
    /**
     * 
     * @type {number}
     * @memberof PaginatedTrainingList
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof PaginatedTrainingList
     */
    'next'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaginatedTrainingList
     */
    'previous'?: string | null;
    /**
     * 
     * @type {Array<Training>}
     * @memberof PaginatedTrainingList
     */
    'results': Array<Training>;
}
/**
 * 
 * @export
 * @interface PasswordChange
 */
export interface PasswordChange {
    /**
     * 
     * @type {string}
     * @memberof PasswordChange
     */
    'new_password1': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChange
     */
    'new_password2': string;
}
/**
 * Serializer for confirming a password reset attempt.
 * @export
 * @interface PasswordResetConfirm
 */
export interface PasswordResetConfirm {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirm
     */
    'new_password1': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirm
     */
    'new_password2': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirm
     */
    'uid': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirm
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface PatchedAssignment
 */
export interface PatchedAssignment {
    /**
     * 
     * @type {string}
     * @memberof PatchedAssignment
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedAssignment
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedAssignment
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedAssignment
     */
    'descending_leaderboard'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PatchedAssignment
     */
    'leaderboard_limit'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchedAssignment
     */
    'courses'?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof PatchedAssignment
     */
    'created_at'?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchedAssignment
     */
    'updated_at'?: Date;
}
/**
 * 
 * @export
 * @interface PatchedCourse
 */
export interface PatchedCourse {
    /**
     * 
     * @type {string}
     * @memberof PatchedCourse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedCourse
     */
    'title'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchedCourse
     */
    'submission_interval_sec'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedCourse
     */
    'gapi_sa_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedCourse
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedCourse
     */
    'workbook_config'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedCourse
     */
    'default_duration_minutes'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedCourse
     */
    'is_gpu'?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PatchedCourse
     */
    'created_at'?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchedCourse
     */
    'updated_at'?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedCourse
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedCourse
     */
    'contain_videos'?: boolean;
}
/**
 * 
 * @export
 * @interface PatchedDataset
 */
export interface PatchedDataset {
    /**
     * 
     * @type {string}
     * @memberof PatchedDataset
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedDataset
     */
    'assignment'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedDataset
     */
    'filename'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedDataset
     */
    'url'?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatchedDataset
     */
    'updated_at'?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchedDataset
     */
    'created_at'?: Date;
}
/**
 * 
 * @export
 * @interface PatchedForumRecordDetailed
 */
export interface PatchedForumRecordDetailed {
    /**
     * 
     * @type {string}
     * @memberof PatchedForumRecordDetailed
     */
    'created_by_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedForumRecordDetailed
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedForumRecordDetailed
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedForumRecordDetailed
     */
    'content'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedForumRecordDetailed
     */
    'edited'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedForumRecordDetailed
     */
    'parent_record'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedForumRecordDetailed
     */
    'upvotes_count'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedForumRecordDetailed
     */
    'is_announcement'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedForumRecordDetailed
     */
    'did_upvote'?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PatchedForumRecordDetailed
     */
    'created_at'?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchedForumRecordDetailed
     */
    'updated_at'?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedForumRecordDetailed
     */
    'is_deleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedForumRecordDetailed
     */
    'created_by_username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedForumRecordDetailed
     */
    'created_by_icon'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedForumRecordDetailed
     */
    'course'?: string | null;
    /**
     * 
     * @type {Array<ForumFileUpload>}
     * @memberof PatchedForumRecordDetailed
     */
    'forum_file_uploads'?: Array<ForumFileUpload>;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedForumRecordDetailed
     */
    'pinned_status'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedForumRecordDetailed
     */
    'read_status'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedForumRecordDetailed
     */
    'is_scheduled_announcement'?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PatchedForumRecordDetailed
     */
    'scheduled_date'?: Date | null;
}
/**
 * 
 * @export
 * @interface PatchedTestType
 */
export interface PatchedTestType {
    /**
     * 
     * @type {string}
     * @memberof PatchedTestType
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedTestType
     */
    'title'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedTestType
     */
    'is_locked'?: boolean;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PatchedTestType
     */
    'arguments_types'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof PatchedTestType
     */
    'evaluation_code'?: string;
    /**
     * 
     * @type {Date}
     * @memberof PatchedTestType
     */
    'created_at'?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchedTestType
     */
    'updated_at'?: Date;
}
/**
 * 
 * @export
 * @interface PatchedUser
 */
export interface PatchedUser {
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    'affiliation'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    'icon'?: string;
}
/**
 * 
 * @export
 * @interface Problem
 */
export interface Problem {
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    'generator'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Problem
     */
    'difficulty': number;
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    'category'?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Problem
     */
    'description'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    'preprocess_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    'postprocess_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    'solution_template'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Problem
     */
    'max_bytes'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    'validation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Problem
     */
    'validation_tests'?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Problem
     */
    'answer': { [key: string]: any; };
}
/**
 * 
 * @export
 * @interface PublicUser
 */
export interface PublicUser {
    /**
     * 
     * @type {string}
     * @memberof PublicUser
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof PublicUser
     */
    'affiliation'?: string;
}
/**
 * 
 * @export
 * @interface Quiz
 */
export interface Quiz {
    /**
     * 
     * @type {number}
     * @memberof Quiz
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Quiz
     */
    'title': string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Quiz
     */
    'questions'?: { [key: string]: any; };
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof Quiz
     */
    'answer'?: { [key: string]: any; };
    /**
     * 
     * @type {Array<string>}
     * @memberof Quiz
     */
    'courses': Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof Quiz
     */
    'created_at': Date;
    /**
     * 
     * @type {Date}
     * @memberof Quiz
     */
    'updated_at': Date;
    /**
     * 
     * @type {string}
     * @memberof Quiz
     */
    'created_by'?: string | null;
}
/**
 * 
 * @export
 * @interface QuizContent
 */
export interface QuizContent {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof QuizContent
     */
    'questions': { [key: string]: any; };
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof QuizContent
     */
    'answers': { [key: string]: any; };
}
/**
 * fetches quiz\'s user/course specific details (expiry, user\'s submission status)
 * @export
 * @interface QuizCourseSpecific
 */
export interface QuizCourseSpecific {
    /**
     * 
     * @type {number}
     * @memberof QuizCourseSpecific
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof QuizCourseSpecific
     */
    'title': string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof QuizCourseSpecific
     */
    'questions'?: { [key: string]: any; };
    /**
     * 
     * @type {Array<string>}
     * @memberof QuizCourseSpecific
     */
    'courses': Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof QuizCourseSpecific
     */
    'created_at': Date;
    /**
     * 
     * @type {Date}
     * @memberof QuizCourseSpecific
     */
    'updated_at': Date;
    /**
     * 
     * @type {Date}
     * @memberof QuizCourseSpecific
     */
    'expiry_date': Date;
    /**
     * 
     * @type {boolean}
     * @memberof QuizCourseSpecific
     */
    'submitted': boolean;
    /**
     * 
     * @type {string}
     * @memberof QuizCourseSpecific
     */
    'quiz_course_id': string;
    /**
     * 
     * @type {Array<boolean>}
     * @memberof QuizCourseSpecific
     */
    'is_single_choice': Array<boolean>;
}
/**
 * 
 * @export
 * @interface QuizSubmission
 */
export interface QuizSubmission {
    /**
     * 
     * @type {string}
     * @memberof QuizSubmission
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof QuizSubmission
     */
    'score'?: number;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof QuizSubmission
     */
    'user_answer'?: { [key: string]: any; };
    /**
     * 
     * @type {Date}
     * @memberof QuizSubmission
     */
    'created_at': Date;
}
/**
 * 
 * @export
 * @interface Register
 */
export interface Register {
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'password1': string;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'password2': string;
}
/**
 * 
 * @export
 * @interface ResendEmailVerification
 */
export interface ResendEmailVerification {
    /**
     * 
     * @type {string}
     * @memberof ResendEmailVerification
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface RestAuthDetail
 */
export interface RestAuthDetail {
    /**
     * 
     * @type {string}
     * @memberof RestAuthDetail
     */
    'detail': string;
}
/**
 * 
 * @export
 * @interface Roles
 */
export interface Roles {
    /**
     * 
     * @type {string}
     * @memberof Roles
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ScheduledAnnouncementsFinalize
 */
export interface ScheduledAnnouncementsFinalize {
    /**
     * 
     * @type {string}
     * @memberof ScheduledAnnouncementsFinalize
     */
    'forum_id': string;
    /**
     * 
     * @type {Date}
     * @memberof ScheduledAnnouncementsFinalize
     */
    'scheduled_datetime'?: Date;
}
/**
 * 
 * @export
 * @interface SignedURL
 */
export interface SignedURL {
    /**
     * 
     * @type {string}
     * @memberof SignedURL
     */
    'filename': string;
}
/**
 * * `TE` - Technical * `SO` - Soft
 * @export
 * @enum {string}
 */

export const SkillTypeEnum = {
    Te: 'TE',
    So: 'SO'
} as const;

export type SkillTypeEnum = typeof SkillTypeEnum[keyof typeof SkillTypeEnum];


/**
 * 
 * @export
 * @interface SocialLogin
 */
export interface SocialLogin {
    /**
     * 
     * @type {string}
     * @memberof SocialLogin
     */
    'access_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialLogin
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialLogin
     */
    'id_token'?: string;
}
/**
 * * `CO` - Completed * `CA` - Canceled * `SC` - Scheduled * `PD` - Pending * `EX` - Executing * `IF` - Invalidformat * `IE` - Internalerror * `WR` - Wrong * `CR` - Correct
 * @export
 * @enum {string}
 */

export const StatusEnum = {
    Co: 'CO',
    Ca: 'CA',
    Sc: 'SC',
    Pd: 'PD',
    Ex: 'EX',
    If: 'IF',
    Ie: 'IE',
    Wr: 'WR',
    Cr: 'CR'
} as const;

export type StatusEnum = typeof StatusEnum[keyof typeof StatusEnum];


/**
 * 
 * @export
 * @interface Student
 */
export interface Student {
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof Student
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface SubmissionDetailed
 */
export interface SubmissionDetailed {
    /**
     * 
     * @type {string}
     * @memberof SubmissionDetailed
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionDetailed
     */
    'submitted_csv': string;
    /**
     * 
     * @type {Date}
     * @memberof SubmissionDetailed
     */
    'submission_expiry': Date;
    /**
     * 
     * @type {string}
     * @memberof SubmissionDetailed
     */
    'assignment_id': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionDetailed
     */
    'assignment_title': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionDetailed
     */
    'course_id': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionDetailed
     */
    'course_title': string;
    /**
     * 
     * @type {number}
     * @memberof SubmissionDetailed
     */
    'score': number;
    /**
     * 
     * @type {StatusEnum}
     * @memberof SubmissionDetailed
     */
    'status': StatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SubmissionDetailed
     */
    'is_final': boolean;
    /**
     * 
     * @type {string}
     * @memberof SubmissionDetailed
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionDetailed
     */
    'created_by_username': string | null;
    /**
     * 
     * @type {string}
     * @memberof SubmissionDetailed
     */
    'created_by_icon': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionDetailed
     */
    'created_by'?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SubmissionDetailed
     */
    'created_at': Date;
}


/**
 * 
 * @export
 * @interface SubmissionList
 */
export interface SubmissionList {
    /**
     * 
     * @type {string}
     * @memberof SubmissionList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionList
     */
    'assignment_id': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionList
     */
    'assignment_title': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionList
     */
    'course_id': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionList
     */
    'course_title': string;
    /**
     * 
     * @type {number}
     * @memberof SubmissionList
     */
    'score': number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionList
     */
    'comment': string;
    /**
     * 
     * @type {boolean}
     * @memberof SubmissionList
     */
    'is_final': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SubmissionList
     */
    'is_late': boolean;
    /**
     * 
     * @type {StatusEnum}
     * @memberof SubmissionList
     */
    'status': StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SubmissionList
     */
    'created_by_username': string | null;
    /**
     * 
     * @type {string}
     * @memberof SubmissionList
     */
    'created_by_icon': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionList
     */
    'created_by'?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SubmissionList
     */
    'created_at': Date;
}


/**
 * 
 * @export
 * @interface SubmissionListAdmin
 */
export interface SubmissionListAdmin {
    /**
     * 
     * @type {string}
     * @memberof SubmissionListAdmin
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionListAdmin
     */
    'assignment_id': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionListAdmin
     */
    'assignment_title': string;
    /**
     * 
     * @type {number}
     * @memberof SubmissionListAdmin
     */
    'score': number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionListAdmin
     */
    'comment': string;
    /**
     * 
     * @type {boolean}
     * @memberof SubmissionListAdmin
     */
    'is_final': boolean;
    /**
     * 
     * @type {StatusEnum}
     * @memberof SubmissionListAdmin
     */
    'status': StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SubmissionListAdmin
     */
    'created_by_username': string | null;
    /**
     * 
     * @type {string}
     * @memberof SubmissionListAdmin
     */
    'created_by_icon': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionListAdmin
     */
    'created_by_email': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionListAdmin
     */
    'created_by'?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SubmissionListAdmin
     */
    'created_at': Date;
}


/**
 * 
 * @export
 * @interface SyncVimeoVideo
 */
export interface SyncVimeoVideo {
    /**
     * 
     * @type {string}
     * @memberof SyncVimeoVideo
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface TestCaseData
 */
export interface TestCaseData {
    /**
     * 
     * @type {string}
     * @memberof TestCaseData
     */
    'data': string;
}
/**
 * 
 * @export
 * @interface TestType
 */
export interface TestType {
    /**
     * 
     * @type {string}
     * @memberof TestType
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TestType
     */
    'title': string;
    /**
     * 
     * @type {boolean}
     * @memberof TestType
     */
    'is_locked'?: boolean;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof TestType
     */
    'arguments_types'?: { [key: string]: any; };
    /**
     * 
     * @type {string}
     * @memberof TestType
     */
    'evaluation_code': string;
    /**
     * 
     * @type {Date}
     * @memberof TestType
     */
    'created_at': Date;
    /**
     * 
     * @type {Date}
     * @memberof TestType
     */
    'updated_at': Date;
}
/**
 * 
 * @export
 * @interface TokenObtainPair
 */
export interface TokenObtainPair {
    /**
     * 
     * @type {string}
     * @memberof TokenObtainPair
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof TokenObtainPair
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof TokenObtainPair
     */
    'access': string;
    /**
     * 
     * @type {string}
     * @memberof TokenObtainPair
     */
    'refresh': string;
}
/**
 * 
 * @export
 * @interface TokenRefresh
 */
export interface TokenRefresh {
    /**
     * 
     * @type {string}
     * @memberof TokenRefresh
     */
    'access': string;
    /**
     * 
     * @type {string}
     * @memberof TokenRefresh
     */
    'refresh': string;
}
/**
 * 
 * @export
 * @interface Training
 */
export interface Training {
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Training
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof Training
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Training
     */
    'problems_count': number;
}
/**
 * 
 * @export
 * @interface TrainingDetail
 */
export interface TrainingDetail {
    /**
     * 
     * @type {string}
     * @memberof TrainingDetail
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TrainingDetail
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof TrainingDetail
     */
    'enabled'?: boolean;
    /**
     * 
     * @type {Array<any>}
     * @memberof TrainingDetail
     */
    'problems': Array<any>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'affiliation'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'icon'?: string;
}
/**
 * User model w/o password
 * @export
 * @interface UserDetails
 */
export interface UserDetails {
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'pk': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'last_name'?: string;
}
/**
 * 
 * @export
 * @interface UserRoles
 */
export interface UserRoles {
    /**
     * 
     * @type {string}
     * @memberof UserRoles
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserRoles
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserRoles
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserRoles
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserRoles
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRoles
     */
    'roles': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserRoles
     */
    'username': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRoles
     */
    'editor_courses': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserRoles
     */
    'is_staff': boolean;
}
/**
 * 
 * @export
 * @interface VerifyEmail
 */
export interface VerifyEmail {
    /**
     * 
     * @type {string}
     * @memberof VerifyEmail
     */
    'key': string;
}
/**
 * 
 * @export
 * @interface VimeoVideo
 */
export interface VimeoVideo {
    /**
     * 
     * @type {string}
     * @memberof VimeoVideo
     */
    'video_id': string;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideo
     */
    'player_embed_url': string;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideo
     */
    'name': string;
    /**
     * 
     * @type {Date}
     * @memberof VimeoVideo
     */
    'video_created_time': Date;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideo
     */
    'thumbnail_url': string;
    /**
     * 
     * @type {number}
     * @memberof VimeoVideo
     */
    'duration_seconds': number;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideo
     */
    'course': string;
    /**
     * 
     * @type {string}
     * @memberof VimeoVideo
     */
    'platform': string;
}
/**
 * 
 * @export
 * @interface VimeoVideoNameOnly
 */
export interface VimeoVideoNameOnly {
    /**
     * 
     * @type {string}
     * @memberof VimeoVideoNameOnly
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface WorkbookConfig
 */
export interface WorkbookConfig {
    /**
     * 
     * @type {string}
     * @memberof WorkbookConfig
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkbookConfig
     */
    'is_gpu'?: boolean;
    /**
     * 
     * @type {GpuTypeEnum}
     * @memberof WorkbookConfig
     */
    'gpu_type'?: GpuTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof WorkbookConfig
     */
    'default_duration_minutes'?: number;
    /**
     * 
     * @type {number}
     * @memberof WorkbookConfig
     */
    'maximum_duration_minutes'?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkbookConfig
     */
    'public_dir'?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkbookConfig
     */
    'image': string;
    /**
     * 
     * @type {string}
     * @memberof WorkbookConfig
     */
    'course_id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkbookConfig
     */
    'course_title': string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkbookConfig
     */
    'is_launchable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkbookConfig
     */
    'enable_workbook_limit': boolean;
    /**
     * 
     * @type {number}
     * @memberof WorkbookConfig
     */
    'monthly_used_minutes_gpu': number;
    /**
     * 
     * @type {number}
     * @memberof WorkbookConfig
     */
    'monthly_used_minutes_cpu': number;
    /**
     * 
     * @type {number}
     * @memberof WorkbookConfig
     */
    'monthly_max_usage_minutes_cpu': number;
    /**
     * 
     * @type {number}
     * @memberof WorkbookConfig
     */
    'monthly_max_usage_minutes_gpu': number;
}


/**
 * 
 * @export
 * @interface WorkbookConfigList
 */
export interface WorkbookConfigList {
    /**
     * 
     * @type {string}
     * @memberof WorkbookConfigList
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface Workshop
 */
export interface Workshop {
    /**
     * 
     * @type {number}
     * @memberof Workshop
     */
    'range_from': number;
    /**
     * 
     * @type {number}
     * @memberof Workshop
     */
    'range_to': number;
    /**
     * 
     * @type {number}
     * @memberof Workshop
     */
    'score': number;
    /**
     * 
     * @type {string}
     * @memberof Workshop
     */
    'skill': string;
}

/**
 * AssessmentAnalysisApi - axios parameter creator
 * @export
 */
export const AssessmentAnalysisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assessmentAnalysisExistenceCourseRetrieve: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('assessmentAnalysisExistenceCourseRetrieve', 'courseId', courseId)
            const localVarPath = `/api/AssessmentAnalysis/Existence/Course/{course_id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [course] 
         * @param {string} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assessmentAnalysisFeedbackList: async (course?: string, user?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AssessmentAnalysis/Feedback/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (course !== undefined) {
                localVarQueryParameter['course'] = course;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary AssessmentAnalysisList
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assessmentAnalysisList: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('assessmentAnalysisList', 'courseId', courseId)
            const localVarPath = `/api/AssessmentAnalysis/{course_id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary AssessmentAnalysisDetail
         * @param {string} courseId 
         * @param {string} userId 
         * @param {string} [skillType] Filter by skill type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assessmentAnalysisUserSpecific: async (courseId: string, userId: string, skillType?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('assessmentAnalysisUserSpecific', 'courseId', courseId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('assessmentAnalysisUserSpecific', 'userId', userId)
            const localVarPath = `/api/AssessmentAnalysis/{course_id}/{user_id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skillType !== undefined) {
                localVarQueryParameter['skill_type'] = skillType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary AssessmentAnalysisDetailAssignmentHistogram
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assessmentAnalysisUserSpecificAssignmentHistogram: async (assignmentId: string, courseId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('assessmentAnalysisUserSpecificAssignmentHistogram', 'assignmentId', assignmentId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('assessmentAnalysisUserSpecificAssignmentHistogram', 'courseId', courseId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('assessmentAnalysisUserSpecificAssignmentHistogram', 'userId', userId)
            const localVarPath = `/api/AssessmentAnalysis/histogram/assignment/{course_id}/{user_id}/{assignment_id}`
                .replace(`{${"assignment_id"}}`, encodeURIComponent(String(assignmentId)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary AssessmentAnalysisDetailSkillHistogram
         * @param {string} courseId 
         * @param {string} skillName 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assessmentAnalysisUserSpecificSkillHistogram: async (courseId: string, skillName: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('assessmentAnalysisUserSpecificSkillHistogram', 'courseId', courseId)
            // verify required parameter 'skillName' is not null or undefined
            assertParamExists('assessmentAnalysisUserSpecificSkillHistogram', 'skillName', skillName)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('assessmentAnalysisUserSpecificSkillHistogram', 'userId', userId)
            const localVarPath = `/api/AssessmentAnalysis/histogram/skill/{course_id}/{user_id}/{skill_name}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"skill_name"}}`, encodeURIComponent(String(skillName)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [course] 
         * @param {string} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assessmentAnalysisWorkshopList: async (course?: string, user?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AssessmentAnalysis/Workshop/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (course !== undefined) {
                localVarQueryParameter['course'] = course;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssessmentAnalysisApi - functional programming interface
 * @export
 */
export const AssessmentAnalysisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssessmentAnalysisApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assessmentAnalysisExistenceCourseRetrieve(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assessmentAnalysisExistenceCourseRetrieve(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentAnalysisApi.assessmentAnalysisExistenceCourseRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [course] 
         * @param {string} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assessmentAnalysisFeedbackList(course?: string, user?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Feedback>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assessmentAnalysisFeedbackList(course, user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentAnalysisApi.assessmentAnalysisFeedbackList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary AssessmentAnalysisList
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assessmentAnalysisList(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssessmentAnalysisSerializerList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assessmentAnalysisList(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentAnalysisApi.assessmentAnalysisList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary AssessmentAnalysisDetail
         * @param {string} courseId 
         * @param {string} userId 
         * @param {string} [skillType] Filter by skill type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assessmentAnalysisUserSpecific(courseId: string, userId: string, skillType?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssessmentAnalysisDetailAPIResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assessmentAnalysisUserSpecific(courseId, userId, skillType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentAnalysisApi.assessmentAnalysisUserSpecific']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary AssessmentAnalysisDetailAssignmentHistogram
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assessmentAnalysisUserSpecificAssignmentHistogram(assignmentId: string, courseId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Histogram>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assessmentAnalysisUserSpecificAssignmentHistogram(assignmentId, courseId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentAnalysisApi.assessmentAnalysisUserSpecificAssignmentHistogram']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary AssessmentAnalysisDetailSkillHistogram
         * @param {string} courseId 
         * @param {string} skillName 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assessmentAnalysisUserSpecificSkillHistogram(courseId: string, skillName: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Histogram>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assessmentAnalysisUserSpecificSkillHistogram(courseId, skillName, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentAnalysisApi.assessmentAnalysisUserSpecificSkillHistogram']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [course] 
         * @param {string} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assessmentAnalysisWorkshopList(course?: string, user?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Workshop>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assessmentAnalysisWorkshopList(course, user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssessmentAnalysisApi.assessmentAnalysisWorkshopList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssessmentAnalysisApi - factory interface
 * @export
 */
export const AssessmentAnalysisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssessmentAnalysisApiFp(configuration)
    return {
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assessmentAnalysisExistenceCourseRetrieve(courseId: string, options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.assessmentAnalysisExistenceCourseRetrieve(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [course] 
         * @param {string} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assessmentAnalysisFeedbackList(course?: string, user?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Feedback>> {
            return localVarFp.assessmentAnalysisFeedbackList(course, user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary AssessmentAnalysisList
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assessmentAnalysisList(courseId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AssessmentAnalysisSerializerList>> {
            return localVarFp.assessmentAnalysisList(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary AssessmentAnalysisDetail
         * @param {string} courseId 
         * @param {string} userId 
         * @param {string} [skillType] Filter by skill type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assessmentAnalysisUserSpecific(courseId: string, userId: string, skillType?: string, options?: RawAxiosRequestConfig): AxiosPromise<AssessmentAnalysisDetailAPIResponse> {
            return localVarFp.assessmentAnalysisUserSpecific(courseId, userId, skillType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary AssessmentAnalysisDetailAssignmentHistogram
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assessmentAnalysisUserSpecificAssignmentHistogram(assignmentId: string, courseId: string, userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Histogram>> {
            return localVarFp.assessmentAnalysisUserSpecificAssignmentHistogram(assignmentId, courseId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary AssessmentAnalysisDetailSkillHistogram
         * @param {string} courseId 
         * @param {string} skillName 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assessmentAnalysisUserSpecificSkillHistogram(courseId: string, skillName: string, userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Histogram>> {
            return localVarFp.assessmentAnalysisUserSpecificSkillHistogram(courseId, skillName, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [course] 
         * @param {string} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assessmentAnalysisWorkshopList(course?: string, user?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Workshop>> {
            return localVarFp.assessmentAnalysisWorkshopList(course, user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssessmentAnalysisApi - object-oriented interface
 * @export
 * @class AssessmentAnalysisApi
 * @extends {BaseAPI}
 */
export class AssessmentAnalysisApi extends BaseAPI {
    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentAnalysisApi
     */
    public assessmentAnalysisExistenceCourseRetrieve(courseId: string, options?: RawAxiosRequestConfig) {
        return AssessmentAnalysisApiFp(this.configuration).assessmentAnalysisExistenceCourseRetrieve(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [course] 
     * @param {string} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentAnalysisApi
     */
    public assessmentAnalysisFeedbackList(course?: string, user?: string, options?: RawAxiosRequestConfig) {
        return AssessmentAnalysisApiFp(this.configuration).assessmentAnalysisFeedbackList(course, user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary AssessmentAnalysisList
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentAnalysisApi
     */
    public assessmentAnalysisList(courseId: string, options?: RawAxiosRequestConfig) {
        return AssessmentAnalysisApiFp(this.configuration).assessmentAnalysisList(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary AssessmentAnalysisDetail
     * @param {string} courseId 
     * @param {string} userId 
     * @param {string} [skillType] Filter by skill type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentAnalysisApi
     */
    public assessmentAnalysisUserSpecific(courseId: string, userId: string, skillType?: string, options?: RawAxiosRequestConfig) {
        return AssessmentAnalysisApiFp(this.configuration).assessmentAnalysisUserSpecific(courseId, userId, skillType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary AssessmentAnalysisDetailAssignmentHistogram
     * @param {string} assignmentId 
     * @param {string} courseId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentAnalysisApi
     */
    public assessmentAnalysisUserSpecificAssignmentHistogram(assignmentId: string, courseId: string, userId: string, options?: RawAxiosRequestConfig) {
        return AssessmentAnalysisApiFp(this.configuration).assessmentAnalysisUserSpecificAssignmentHistogram(assignmentId, courseId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary AssessmentAnalysisDetailSkillHistogram
     * @param {string} courseId 
     * @param {string} skillName 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentAnalysisApi
     */
    public assessmentAnalysisUserSpecificSkillHistogram(courseId: string, skillName: string, userId: string, options?: RawAxiosRequestConfig) {
        return AssessmentAnalysisApiFp(this.configuration).assessmentAnalysisUserSpecificSkillHistogram(courseId, skillName, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [course] 
     * @param {string} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssessmentAnalysisApi
     */
    public assessmentAnalysisWorkshopList(course?: string, user?: string, options?: RawAxiosRequestConfig) {
        return AssessmentAnalysisApiFp(this.configuration).assessmentAnalysisWorkshopList(course, user, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AssignmentApi - axios parameter creator
 * @export
 */
export const AssignmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} courseId course_id required for finding submission_expiry
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentCourseSpecificDetailRetrieve: async (courseId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('assignmentCourseSpecificDetailRetrieve', 'courseId', courseId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignmentCourseSpecificDetailRetrieve', 'id', id)
            const localVarPath = `/api/Assignment/{id}/CourseSpecificDetail/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['course_id'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} courseId all-assignments for course_id
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentCourseSpecificList: async (courseId: string, ordering?: string, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('assignmentCourseSpecificList', 'courseId', courseId)
            const localVarPath = `/api/Assignment/CourseSpecific/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['course_id'] = courseId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentCourseTestCaseList: async (assignmentId: string, courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('assignmentCourseTestCaseList', 'assignmentId', assignmentId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('assignmentCourseTestCaseList', 'courseId', courseId)
            const localVarPath = `/api/Assignment/{assignment_id}/{course_id}/TestCase/`
                .replace(`{${"assignment_id"}}`, encodeURIComponent(String(assignmentId)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {Assignment} assignment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentCreate: async (assignment: Assignment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignment' is not null or undefined
            assertParamExists('assignmentCreate', 'assignment', assignment)
            const localVarPath = `/api/Assignment/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentDestroy: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignmentDestroy', 'id', id)
            const localVarPath = `/api/Assignment/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {string} id 
         * @param {string} url 
         * @param {Date} createdAt 
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentFileUploadCreate: async (assignmentId: string, courseId: string, id: string, url: string, createdAt: Date, filename?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('assignmentFileUploadCreate', 'assignmentId', assignmentId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('assignmentFileUploadCreate', 'courseId', courseId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignmentFileUploadCreate', 'id', id)
            // verify required parameter 'url' is not null or undefined
            assertParamExists('assignmentFileUploadCreate', 'url', url)
            // verify required parameter 'createdAt' is not null or undefined
            assertParamExists('assignmentFileUploadCreate', 'createdAt', createdAt)
            const localVarPath = `/api/Assignment/{assignment_id}/{course_id}/FileUpload/`
                .replace(`{${"assignment_id"}}`, encodeURIComponent(String(assignmentId)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (id !== undefined) { 
                localVarFormParams.append('id', id as any);
            }
    
            if (filename !== undefined) { 
                localVarFormParams.append('filename', filename as any);
            }
    
            if (url !== undefined) { 
                localVarFormParams.append('url', url as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('created_at', createdAt as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentFileUploadDestroy: async (assignmentId: string, courseId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('assignmentFileUploadDestroy', 'assignmentId', assignmentId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('assignmentFileUploadDestroy', 'courseId', courseId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignmentFileUploadDestroy', 'id', id)
            const localVarPath = `/api/Assignment/{assignment_id}/{course_id}/FileUpload/{id}/`
                .replace(`{${"assignment_id"}}`, encodeURIComponent(String(assignmentId)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentFileUploadList: async (assignmentId: string, courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('assignmentFileUploadList', 'assignmentId', assignmentId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('assignmentFileUploadList', 'courseId', courseId)
            const localVarPath = `/api/Assignment/{assignment_id}/{course_id}/FileUpload/`
                .replace(`{${"assignment_id"}}`, encodeURIComponent(String(assignmentId)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {Array<string>} [courses] 
         * @param {string} [createdBy] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentList: async (courses?: Array<string>, createdBy?: string, ordering?: string, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Assignment/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courses) {
                localVarQueryParameter['courses'] = courses;
            }

            if (createdBy !== undefined) {
                localVarQueryParameter['created_by'] = createdBy;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} id A UUID string identifying this assignment.
         * @param {PatchedAssignment} [patchedAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentPartialUpdate: async (id: string, patchedAssignment?: PatchedAssignment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignmentPartialUpdate', 'id', id)
            const localVarPath = `/api/Assignment/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedAssignment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignmentRetrieve', 'id', id)
            const localVarPath = `/api/Assignment/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} courseId 
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentTotalScoreRetrieve: async (courseId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('assignmentTotalScoreRetrieve', 'courseId', courseId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignmentTotalScoreRetrieve', 'id', id)
            const localVarPath = `/api/Assignment/{id}/total_score/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['course_id'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} id A UUID string identifying this assignment.
         * @param {Assignment} assignment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentUpdate: async (id: string, assignment: Assignment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('assignmentUpdate', 'id', id)
            // verify required parameter 'assignment' is not null or undefined
            assertParamExists('assignmentUpdate', 'assignment', assignment)
            const localVarPath = `/api/Assignment/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssignmentApi - functional programming interface
 * @export
 */
export const AssignmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssignmentApiAxiosParamCreator(configuration)
    return {
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} courseId course_id required for finding submission_expiry
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentCourseSpecificDetailRetrieve(courseId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentCourseSpecific>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentCourseSpecificDetailRetrieve(courseId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssignmentApi.assignmentCourseSpecificDetailRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} courseId all-assignments for course_id
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentCourseSpecificList(courseId: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssignmentCourseSpecific>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentCourseSpecificList(courseId, ordering, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssignmentApi.assignmentCourseSpecificList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentCourseTestCaseList(assignmentId: string, courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssignmentCourseTestCaseList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentCourseTestCaseList(assignmentId, courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssignmentApi.assignmentCourseTestCaseList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {Assignment} assignment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentCreate(assignment: Assignment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assignment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentCreate(assignment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssignmentApi.assignmentCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentDestroy(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssignmentApi.assignmentDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {string} id 
         * @param {string} url 
         * @param {Date} createdAt 
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentFileUploadCreate(assignmentId: string, courseId: string, id: string, url: string, createdAt: Date, filename?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentFileSubmission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentFileUploadCreate(assignmentId, courseId, id, url, createdAt, filename, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssignmentApi.assignmentFileUploadCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentFileUploadDestroy(assignmentId: string, courseId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentFileUploadDestroy(assignmentId, courseId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssignmentApi.assignmentFileUploadDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentFileUploadList(assignmentId: string, courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssignmentFileSubmission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentFileUploadList(assignmentId, courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssignmentApi.assignmentFileUploadList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {Array<string>} [courses] 
         * @param {string} [createdBy] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentList(courses?: Array<string>, createdBy?: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Assignment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentList(courses, createdBy, ordering, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssignmentApi.assignmentList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} id A UUID string identifying this assignment.
         * @param {PatchedAssignment} [patchedAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentPartialUpdate(id: string, patchedAssignment?: PatchedAssignment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assignment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentPartialUpdate(id, patchedAssignment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssignmentApi.assignmentPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assignment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssignmentApi.assignmentRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} courseId 
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentTotalScoreRetrieve(courseId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentTotalScoreRetrieve(courseId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssignmentApi.assignmentTotalScoreRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} id A UUID string identifying this assignment.
         * @param {Assignment} assignment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignmentUpdate(id: string, assignment: Assignment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assignment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignmentUpdate(id, assignment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssignmentApi.assignmentUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssignmentApi - factory interface
 * @export
 */
export const AssignmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssignmentApiFp(configuration)
    return {
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} courseId course_id required for finding submission_expiry
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentCourseSpecificDetailRetrieve(courseId: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<AssignmentCourseSpecific> {
            return localVarFp.assignmentCourseSpecificDetailRetrieve(courseId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} courseId all-assignments for course_id
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentCourseSpecificList(courseId: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AssignmentCourseSpecific>> {
            return localVarFp.assignmentCourseSpecificList(courseId, ordering, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentCourseTestCaseList(assignmentId: string, courseId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AssignmentCourseTestCaseList>> {
            return localVarFp.assignmentCourseTestCaseList(assignmentId, courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {Assignment} assignment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentCreate(assignment: Assignment, options?: RawAxiosRequestConfig): AxiosPromise<Assignment> {
            return localVarFp.assignmentCreate(assignment, options).then((request) => request(axios, basePath));
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentDestroy(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.assignmentDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {string} id 
         * @param {string} url 
         * @param {Date} createdAt 
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentFileUploadCreate(assignmentId: string, courseId: string, id: string, url: string, createdAt: Date, filename?: string, options?: RawAxiosRequestConfig): AxiosPromise<AssignmentFileSubmission> {
            return localVarFp.assignmentFileUploadCreate(assignmentId, courseId, id, url, createdAt, filename, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentFileUploadDestroy(assignmentId: string, courseId: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.assignmentFileUploadDestroy(assignmentId, courseId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentFileUploadList(assignmentId: string, courseId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AssignmentFileSubmission>> {
            return localVarFp.assignmentFileUploadList(assignmentId, courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {Array<string>} [courses] 
         * @param {string} [createdBy] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentList(courses?: Array<string>, createdBy?: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Assignment>> {
            return localVarFp.assignmentList(courses, createdBy, ordering, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} id A UUID string identifying this assignment.
         * @param {PatchedAssignment} [patchedAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentPartialUpdate(id: string, patchedAssignment?: PatchedAssignment, options?: RawAxiosRequestConfig): AxiosPromise<Assignment> {
            return localVarFp.assignmentPartialUpdate(id, patchedAssignment, options).then((request) => request(axios, basePath));
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentRetrieve(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Assignment> {
            return localVarFp.assignmentRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} courseId 
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentTotalScoreRetrieve(courseId: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.assignmentTotalScoreRetrieve(courseId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Query parameter \'course\' should be set when getting the list of assignments.
         * @param {string} id A UUID string identifying this assignment.
         * @param {Assignment} assignment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignmentUpdate(id: string, assignment: Assignment, options?: RawAxiosRequestConfig): AxiosPromise<Assignment> {
            return localVarFp.assignmentUpdate(id, assignment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssignmentApi - object-oriented interface
 * @export
 * @class AssignmentApi
 * @extends {BaseAPI}
 */
export class AssignmentApi extends BaseAPI {
    /**
     * Query parameter \'course\' should be set when getting the list of assignments.
     * @param {string} courseId course_id required for finding submission_expiry
     * @param {string} id A UUID string identifying this assignment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentCourseSpecificDetailRetrieve(courseId: string, id: string, options?: RawAxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentCourseSpecificDetailRetrieve(courseId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query parameter \'course\' should be set when getting the list of assignments.
     * @param {string} courseId all-assignments for course_id
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentCourseSpecificList(courseId: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentCourseSpecificList(courseId, ordering, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} assignmentId 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentCourseTestCaseList(assignmentId: string, courseId: string, options?: RawAxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentCourseTestCaseList(assignmentId, courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query parameter \'course\' should be set when getting the list of assignments.
     * @param {Assignment} assignment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentCreate(assignment: Assignment, options?: RawAxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentCreate(assignment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query parameter \'course\' should be set when getting the list of assignments.
     * @param {string} id A UUID string identifying this assignment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentDestroy(id: string, options?: RawAxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
     * @param {string} assignmentId 
     * @param {string} courseId 
     * @param {string} id 
     * @param {string} url 
     * @param {Date} createdAt 
     * @param {string} [filename] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentFileUploadCreate(assignmentId: string, courseId: string, id: string, url: string, createdAt: Date, filename?: string, options?: RawAxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentFileUploadCreate(assignmentId, courseId, id, url, createdAt, filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
     * @param {string} assignmentId 
     * @param {string} courseId 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentFileUploadDestroy(assignmentId: string, courseId: string, id: string, options?: RawAxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentFileUploadDestroy(assignmentId, courseId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
     * @param {string} assignmentId 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentFileUploadList(assignmentId: string, courseId: string, options?: RawAxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentFileUploadList(assignmentId, courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query parameter \'course\' should be set when getting the list of assignments.
     * @param {Array<string>} [courses] 
     * @param {string} [createdBy] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentList(courses?: Array<string>, createdBy?: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentList(courses, createdBy, ordering, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query parameter \'course\' should be set when getting the list of assignments.
     * @param {string} id A UUID string identifying this assignment.
     * @param {PatchedAssignment} [patchedAssignment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentPartialUpdate(id: string, patchedAssignment?: PatchedAssignment, options?: RawAxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentPartialUpdate(id, patchedAssignment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query parameter \'course\' should be set when getting the list of assignments.
     * @param {string} id A UUID string identifying this assignment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query parameter \'course\' should be set when getting the list of assignments.
     * @param {string} courseId 
     * @param {string} id A UUID string identifying this assignment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentTotalScoreRetrieve(courseId: string, id: string, options?: RawAxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentTotalScoreRetrieve(courseId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query parameter \'course\' should be set when getting the list of assignments.
     * @param {string} id A UUID string identifying this assignment.
     * @param {Assignment} assignment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignmentApi
     */
    public assignmentUpdate(id: string, assignment: Assignment, options?: RawAxiosRequestConfig) {
        return AssignmentApiFp(this.configuration).assignmentUpdate(id, assignment, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Override succeeding login only when logging in with email. Tell user who has already logged in with a Google account to login with that instead. This is for always refreshing the Google api credentials we might use for this user.
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authEmailLoginCreate: async (login: Login, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'login' is not null or undefined
            assertParamExists('authEmailLoginCreate', 'login', login)
            const localVarPath = `/api/Auth/Email/Login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Register} register 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authEmailRegistrationCreate: async (register: Register, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'register' is not null or undefined
            assertParamExists('authEmailRegistrationCreate', 'register', register)
            const localVarPath = `/api/Auth/Email/Registration/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(register, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResendEmailVerification} resendEmailVerification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authEmailRegistrationResendEmailCreate: async (resendEmailVerification: ResendEmailVerification, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resendEmailVerification' is not null or undefined
            assertParamExists('authEmailRegistrationResendEmailCreate', 'resendEmailVerification', resendEmailVerification)
            const localVarPath = `/api/Auth/Email/Registration/resend-email/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resendEmailVerification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyEmail} verifyEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authEmailRegistrationVerifyEmailCreate: async (verifyEmail: VerifyEmail, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyEmail' is not null or undefined
            assertParamExists('authEmailRegistrationVerifyEmailCreate', 'verifyEmail', verifyEmail)
            const localVarPath = `/api/Auth/Email/Registration/verify-email/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyEmail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordChange} passwordChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordChangeCreate: async (passwordChange: PasswordChange, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordChange' is not null or undefined
            assertParamExists('authPasswordChangeCreate', 'passwordChange', passwordChange)
            const localVarPath = `/api/Auth/password/change/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChange, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordResetConfirm} passwordResetConfirm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordResetConfirmCreate: async (passwordResetConfirm: PasswordResetConfirm, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetConfirm' is not null or undefined
            assertParamExists('authPasswordResetConfirmCreate', 'passwordResetConfirm', passwordResetConfirm)
            const localVarPath = `/api/Auth/password/reset/confirm/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetConfirm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRolesListRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/RolesList/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * class used for social authentications example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter -------------  example usage for facebook with code  ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter from allauth.socialaccount.providers.oauth2.client import OAuth2Client  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter     client_class = OAuth2Client     callback_url = \'localhost:8000\' -------------
         * @param {SocialLogin} [socialLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSocialLoginGoogleCreate: async (socialLogin?: SocialLogin, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/SocialLogin/Google/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(socialLogin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
         * @param {TokenObtainPair} tokenObtainPair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenCreate: async (tokenObtainPair: TokenObtainPair, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenObtainPair' is not null or undefined
            assertParamExists('authTokenCreate', 'tokenObtainPair', tokenObtainPair)
            const localVarPath = `/api/Auth/Token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenObtainPair, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefresh} tokenRefresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenRefreshCreate: async (tokenRefresh: TokenRefresh, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenRefresh' is not null or undefined
            assertParamExists('authTokenRefreshCreate', 'tokenRefresh', tokenRefresh)
            const localVarPath = `/api/Auth/Token/Refresh/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRefresh, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserDestroy: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authUserDestroy', 'id', id)
            const localVarPath = `/api/Auth/User/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserInfoRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auth/UserInfo/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this user.
         * @param {PatchedUser} [patchedUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserPartialUpdate: async (id: string, patchedUser?: PatchedUser, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authUserPartialUpdate', 'id', id)
            const localVarPath = `/api/Auth/User/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authUserRetrieve', 'id', id)
            const localVarPath = `/api/Auth/User/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this user.
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserUpdate: async (id: string, user: User, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authUserUpdate', 'id', id)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('authUserUpdate', 'user', user)
            const localVarPath = `/api/Auth/User/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * Override succeeding login only when logging in with email. Tell user who has already logged in with a Google account to login with that instead. This is for always refreshing the Google api credentials we might use for this user.
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authEmailLoginCreate(login: Login, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Login>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authEmailLoginCreate(login, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authEmailLoginCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Register} register 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authEmailRegistrationCreate(register: Register, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JWT>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authEmailRegistrationCreate(register, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authEmailRegistrationCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ResendEmailVerification} resendEmailVerification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authEmailRegistrationResendEmailCreate(resendEmailVerification: ResendEmailVerification, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authEmailRegistrationResendEmailCreate(resendEmailVerification, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authEmailRegistrationResendEmailCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {VerifyEmail} verifyEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authEmailRegistrationVerifyEmailCreate(verifyEmail: VerifyEmail, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authEmailRegistrationVerifyEmailCreate(verifyEmail, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authEmailRegistrationVerifyEmailCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordChange} passwordChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authPasswordChangeCreate(passwordChange: PasswordChange, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authPasswordChangeCreate(passwordChange, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authPasswordChangeCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordResetConfirm} passwordResetConfirm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authPasswordResetConfirmCreate(passwordResetConfirm: PasswordResetConfirm, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authPasswordResetConfirmCreate(passwordResetConfirm, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authPasswordResetConfirmCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authRolesListRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Roles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authRolesListRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authRolesListRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * class used for social authentications example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter -------------  example usage for facebook with code  ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter from allauth.socialaccount.providers.oauth2.client import OAuth2Client  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter     client_class = OAuth2Client     callback_url = \'localhost:8000\' -------------
         * @param {SocialLogin} [socialLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authSocialLoginGoogleCreate(socialLogin?: SocialLogin, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SocialLogin>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authSocialLoginGoogleCreate(socialLogin, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authSocialLoginGoogleCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
         * @param {TokenObtainPair} tokenObtainPair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authTokenCreate(tokenObtainPair: TokenObtainPair, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenObtainPair>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authTokenCreate(tokenObtainPair, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authTokenCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefresh} tokenRefresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authTokenRefreshCreate(tokenRefresh: TokenRefresh, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenRefresh>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authTokenRefreshCreate(tokenRefresh, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authTokenRefreshCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUserDestroy(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUserDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authUserDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUserInfoRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRoles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUserInfoRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authUserInfoRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this user.
         * @param {PatchedUser} [patchedUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUserPartialUpdate(id: string, patchedUser?: PatchedUser, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUserPartialUpdate(id, patchedUser, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authUserPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUserRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUserRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authUserRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this user.
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUserUpdate(id: string, user: User, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUserUpdate(id, user, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authUserUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * Override succeeding login only when logging in with email. Tell user who has already logged in with a Google account to login with that instead. This is for always refreshing the Google api credentials we might use for this user.
         * @param {Login} login 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authEmailLoginCreate(login: Login, options?: RawAxiosRequestConfig): AxiosPromise<Login> {
            return localVarFp.authEmailLoginCreate(login, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Register} register 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authEmailRegistrationCreate(register: Register, options?: RawAxiosRequestConfig): AxiosPromise<JWT> {
            return localVarFp.authEmailRegistrationCreate(register, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResendEmailVerification} resendEmailVerification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authEmailRegistrationResendEmailCreate(resendEmailVerification: ResendEmailVerification, options?: RawAxiosRequestConfig): AxiosPromise<RestAuthDetail> {
            return localVarFp.authEmailRegistrationResendEmailCreate(resendEmailVerification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyEmail} verifyEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authEmailRegistrationVerifyEmailCreate(verifyEmail: VerifyEmail, options?: RawAxiosRequestConfig): AxiosPromise<RestAuthDetail> {
            return localVarFp.authEmailRegistrationVerifyEmailCreate(verifyEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordChange} passwordChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordChangeCreate(passwordChange: PasswordChange, options?: RawAxiosRequestConfig): AxiosPromise<RestAuthDetail> {
            return localVarFp.authPasswordChangeCreate(passwordChange, options).then((request) => request(axios, basePath));
        },
        /**
         * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordResetConfirm} passwordResetConfirm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPasswordResetConfirmCreate(passwordResetConfirm: PasswordResetConfirm, options?: RawAxiosRequestConfig): AxiosPromise<RestAuthDetail> {
            return localVarFp.authPasswordResetConfirmCreate(passwordResetConfirm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRolesListRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<Roles> {
            return localVarFp.authRolesListRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * class used for social authentications example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter -------------  example usage for facebook with code  ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter from allauth.socialaccount.providers.oauth2.client import OAuth2Client  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter     client_class = OAuth2Client     callback_url = \'localhost:8000\' -------------
         * @param {SocialLogin} [socialLogin] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSocialLoginGoogleCreate(socialLogin?: SocialLogin, options?: RawAxiosRequestConfig): AxiosPromise<SocialLogin> {
            return localVarFp.authSocialLoginGoogleCreate(socialLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
         * @param {TokenObtainPair} tokenObtainPair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenCreate(tokenObtainPair: TokenObtainPair, options?: RawAxiosRequestConfig): AxiosPromise<TokenObtainPair> {
            return localVarFp.authTokenCreate(tokenObtainPair, options).then((request) => request(axios, basePath));
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefresh} tokenRefresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenRefreshCreate(tokenRefresh: TokenRefresh, options?: RawAxiosRequestConfig): AxiosPromise<TokenRefresh> {
            return localVarFp.authTokenRefreshCreate(tokenRefresh, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserDestroy(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.authUserDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserInfoRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<UserRoles> {
            return localVarFp.authUserInfoRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this user.
         * @param {PatchedUser} [patchedUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserPartialUpdate(id: string, patchedUser?: PatchedUser, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.authUserPartialUpdate(id, patchedUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserRetrieve(id: string, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.authUserRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this user.
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserUpdate(id: string, user: User, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.authUserUpdate(id, user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * Override succeeding login only when logging in with email. Tell user who has already logged in with a Google account to login with that instead. This is for always refreshing the Google api credentials we might use for this user.
     * @param {Login} login 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authEmailLoginCreate(login: Login, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authEmailLoginCreate(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Register} register 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authEmailRegistrationCreate(register: Register, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authEmailRegistrationCreate(register, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResendEmailVerification} resendEmailVerification 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authEmailRegistrationResendEmailCreate(resendEmailVerification: ResendEmailVerification, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authEmailRegistrationResendEmailCreate(resendEmailVerification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyEmail} verifyEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authEmailRegistrationVerifyEmailCreate(verifyEmail: VerifyEmail, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authEmailRegistrationVerifyEmailCreate(verifyEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordChange} passwordChange 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authPasswordChangeCreate(passwordChange: PasswordChange, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authPasswordChangeCreate(passwordChange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordResetConfirm} passwordResetConfirm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authPasswordResetConfirmCreate(passwordResetConfirm: PasswordResetConfirm, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authPasswordResetConfirmCreate(passwordResetConfirm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authRolesListRetrieve(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authRolesListRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * class used for social authentications example usage for facebook with access_token ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter -------------  example usage for facebook with code  ------------- from allauth.socialaccount.providers.facebook.views import FacebookOAuth2Adapter from allauth.socialaccount.providers.oauth2.client import OAuth2Client  class FacebookLogin(SocialLoginView):     adapter_class = FacebookOAuth2Adapter     client_class = OAuth2Client     callback_url = \'localhost:8000\' -------------
     * @param {SocialLogin} [socialLogin] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authSocialLoginGoogleCreate(socialLogin?: SocialLogin, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authSocialLoginGoogleCreate(socialLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web token pair to prove the authentication of those credentials.
     * @param {TokenObtainPair} tokenObtainPair 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authTokenCreate(tokenObtainPair: TokenObtainPair, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authTokenCreate(tokenObtainPair, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     * @param {TokenRefresh} tokenRefresh 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authTokenRefreshCreate(tokenRefresh: TokenRefresh, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authTokenRefreshCreate(tokenRefresh, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUserDestroy(id: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authUserDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUserInfoRetrieve(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authUserInfoRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this user.
     * @param {PatchedUser} [patchedUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUserPartialUpdate(id: string, patchedUser?: PatchedUser, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authUserPartialUpdate(id, patchedUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUserRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authUserRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this user.
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUserUpdate(id: string, user: User, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authUserUpdate(id, user, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CourseApi - axios parameter creator
 * @export
 */
export const CourseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Course} course 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseCreate: async (course: Course, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'course' is not null or undefined
            assertParamExists('courseCreate', 'course', course)
            const localVarPath = `/api/Course/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(course, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this course.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseDestroy: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('courseDestroy', 'id', id)
            const localVarPath = `/api/Course/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseList: async (limit?: number, offset?: number, ordering?: string, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Course/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this course.
         * @param {PatchedCourse} [patchedCourse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursePartialUpdate: async (id: string, patchedCourse?: PatchedCourse, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('coursePartialUpdate', 'id', id)
            const localVarPath = `/api/Course/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedCourse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this course.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('courseRetrieve', 'id', id)
            const localVarPath = `/api/Course/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this course.
         * @param {Course} course 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseUpdate: async (id: string, course: Course, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('courseUpdate', 'id', id)
            // verify required parameter 'course' is not null or undefined
            assertParamExists('courseUpdate', 'course', course)
            const localVarPath = `/api/Course/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(course, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseApi - functional programming interface
 * @export
 */
export const CourseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CourseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Course} course 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseCreate(course: Course, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Course>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseCreate(course, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CourseApi.courseCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this course.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseDestroy(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CourseApi.courseDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseList(limit?: number, offset?: number, ordering?: string, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedCourseListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseList(limit, offset, ordering, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CourseApi.courseList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this course.
         * @param {PatchedCourse} [patchedCourse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coursePartialUpdate(id: string, patchedCourse?: PatchedCourse, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Course>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coursePartialUpdate(id, patchedCourse, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CourseApi.coursePartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this course.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Course>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CourseApi.courseRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this course.
         * @param {Course} course 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async courseUpdate(id: string, course: Course, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Course>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.courseUpdate(id, course, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CourseApi.courseUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CourseApi - factory interface
 * @export
 */
export const CourseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CourseApiFp(configuration)
    return {
        /**
         * 
         * @param {Course} course 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseCreate(course: Course, options?: RawAxiosRequestConfig): AxiosPromise<Course> {
            return localVarFp.courseCreate(course, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this course.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseDestroy(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.courseDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseList(limit?: number, offset?: number, ordering?: string, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedCourseListList> {
            return localVarFp.courseList(limit, offset, ordering, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this course.
         * @param {PatchedCourse} [patchedCourse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursePartialUpdate(id: string, patchedCourse?: PatchedCourse, options?: RawAxiosRequestConfig): AxiosPromise<Course> {
            return localVarFp.coursePartialUpdate(id, patchedCourse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this course.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseRetrieve(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Course> {
            return localVarFp.courseRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this course.
         * @param {Course} course 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseUpdate(id: string, course: Course, options?: RawAxiosRequestConfig): AxiosPromise<Course> {
            return localVarFp.courseUpdate(id, course, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CourseApi - object-oriented interface
 * @export
 * @class CourseApi
 * @extends {BaseAPI}
 */
export class CourseApi extends BaseAPI {
    /**
     * 
     * @param {Course} course 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseCreate(course: Course, options?: RawAxiosRequestConfig) {
        return CourseApiFp(this.configuration).courseCreate(course, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this course.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseDestroy(id: string, options?: RawAxiosRequestConfig) {
        return CourseApiFp(this.configuration).courseDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseList(limit?: number, offset?: number, ordering?: string, search?: string, options?: RawAxiosRequestConfig) {
        return CourseApiFp(this.configuration).courseList(limit, offset, ordering, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this course.
     * @param {PatchedCourse} [patchedCourse] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public coursePartialUpdate(id: string, patchedCourse?: PatchedCourse, options?: RawAxiosRequestConfig) {
        return CourseApiFp(this.configuration).coursePartialUpdate(id, patchedCourse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this course.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return CourseApiFp(this.configuration).courseRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this course.
     * @param {Course} course 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseApi
     */
    public courseUpdate(id: string, course: Course, options?: RawAxiosRequestConfig) {
        return CourseApiFp(this.configuration).courseUpdate(id, course, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DatasetApi - axios parameter creator
 * @export
 */
export const DatasetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetCreate: async (dataset: Dataset, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('datasetCreate', 'dataset', dataset)
            const localVarPath = `/api/Dataset/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetDestroy: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('datasetDestroy', 'id', id)
            const localVarPath = `/api/Dataset/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetDownloadCreate: async (dataset: Dataset, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('datasetDownloadCreate', 'dataset', dataset)
            const localVarPath = `/api/Dataset/Download/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetDownloadRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dataset/Download/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetDownloadUpdate: async (dataset: Dataset, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('datasetDownloadUpdate', 'dataset', dataset)
            const localVarPath = `/api/Dataset/Download/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [assignment] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetList: async (assignment?: string, limit?: number, offset?: number, ordering?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dataset/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (assignment !== undefined) {
                localVarQueryParameter['assignment'] = assignment;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this dataset.
         * @param {PatchedDataset} [patchedDataset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetPartialUpdate: async (id: string, patchedDataset?: PatchedDataset, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('datasetPartialUpdate', 'id', id)
            const localVarPath = `/api/Dataset/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedDataset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('datasetRetrieve', 'id', id)
            const localVarPath = `/api/Dataset/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this dataset.
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetUpdate: async (id: string, dataset: Dataset, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('datasetUpdate', 'id', id)
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('datasetUpdate', 'dataset', dataset)
            const localVarPath = `/api/Dataset/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {string} id 
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetUploadCreate: async (id: string, dataset: Dataset, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('datasetUploadCreate', 'id', id)
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('datasetUploadCreate', 'dataset', dataset)
            const localVarPath = `/api/Dataset/Upload/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetUploadRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('datasetUploadRetrieve', 'id', id)
            const localVarPath = `/api/Dataset/Upload/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {string} id 
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetUploadUpdate: async (id: string, dataset: Dataset, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('datasetUploadUpdate', 'id', id)
            // verify required parameter 'dataset' is not null or undefined
            assertParamExists('datasetUploadUpdate', 'dataset', dataset)
            const localVarPath = `/api/Dataset/Upload/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasetApi - functional programming interface
 * @export
 */
export const DatasetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatasetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetCreate(dataset: Dataset, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dataset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetCreate(dataset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.datasetCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetDestroy(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.datasetDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetDownloadCreate(dataset: Dataset, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dataset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetDownloadCreate(dataset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.datasetDownloadCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetDownloadRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dataset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetDownloadRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.datasetDownloadRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetDownloadUpdate(dataset: Dataset, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dataset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetDownloadUpdate(dataset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.datasetDownloadUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [assignment] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetList(assignment?: string, limit?: number, offset?: number, ordering?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedDatasetList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetList(assignment, limit, offset, ordering, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.datasetList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this dataset.
         * @param {PatchedDataset} [patchedDataset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetPartialUpdate(id: string, patchedDataset?: PatchedDataset, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dataset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetPartialUpdate(id, patchedDataset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.datasetPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dataset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.datasetRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this dataset.
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetUpdate(id: string, dataset: Dataset, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dataset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetUpdate(id, dataset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.datasetUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {string} id 
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetUploadCreate(id: string, dataset: Dataset, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dataset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetUploadCreate(id, dataset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.datasetUploadCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetUploadRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dataset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetUploadRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.datasetUploadRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {string} id 
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetUploadUpdate(id: string, dataset: Dataset, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Dataset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetUploadUpdate(id, dataset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetApi.datasetUploadUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DatasetApi - factory interface
 * @export
 */
export const DatasetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatasetApiFp(configuration)
    return {
        /**
         * 
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetCreate(dataset: Dataset, options?: RawAxiosRequestConfig): AxiosPromise<Dataset> {
            return localVarFp.datasetCreate(dataset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetDestroy(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.datasetDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetDownloadCreate(dataset: Dataset, options?: RawAxiosRequestConfig): AxiosPromise<Dataset> {
            return localVarFp.datasetDownloadCreate(dataset, options).then((request) => request(axios, basePath));
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetDownloadRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<Dataset> {
            return localVarFp.datasetDownloadRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetDownloadUpdate(dataset: Dataset, options?: RawAxiosRequestConfig): AxiosPromise<Dataset> {
            return localVarFp.datasetDownloadUpdate(dataset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [assignment] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetList(assignment?: string, limit?: number, offset?: number, ordering?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedDatasetList> {
            return localVarFp.datasetList(assignment, limit, offset, ordering, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this dataset.
         * @param {PatchedDataset} [patchedDataset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetPartialUpdate(id: string, patchedDataset?: PatchedDataset, options?: RawAxiosRequestConfig): AxiosPromise<Dataset> {
            return localVarFp.datasetPartialUpdate(id, patchedDataset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetRetrieve(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Dataset> {
            return localVarFp.datasetRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this dataset.
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetUpdate(id: string, dataset: Dataset, options?: RawAxiosRequestConfig): AxiosPromise<Dataset> {
            return localVarFp.datasetUpdate(id, dataset, options).then((request) => request(axios, basePath));
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {string} id 
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetUploadCreate(id: string, dataset: Dataset, options?: RawAxiosRequestConfig): AxiosPromise<Dataset> {
            return localVarFp.datasetUploadCreate(id, dataset, options).then((request) => request(axios, basePath));
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetUploadRetrieve(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Dataset> {
            return localVarFp.datasetUploadRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
         * @param {string} id 
         * @param {Dataset} dataset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetUploadUpdate(id: string, dataset: Dataset, options?: RawAxiosRequestConfig): AxiosPromise<Dataset> {
            return localVarFp.datasetUploadUpdate(id, dataset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DatasetApi - object-oriented interface
 * @export
 * @class DatasetApi
 * @extends {BaseAPI}
 */
export class DatasetApi extends BaseAPI {
    /**
     * 
     * @param {Dataset} dataset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public datasetCreate(dataset: Dataset, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).datasetCreate(dataset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this dataset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public datasetDestroy(id: string, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).datasetDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
     * @param {Dataset} dataset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public datasetDownloadCreate(dataset: Dataset, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).datasetDownloadCreate(dataset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public datasetDownloadRetrieve(options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).datasetDownloadRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
     * @param {Dataset} dataset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public datasetDownloadUpdate(dataset: Dataset, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).datasetDownloadUpdate(dataset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [assignment] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public datasetList(assignment?: string, limit?: number, offset?: number, ordering?: string, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).datasetList(assignment, limit, offset, ordering, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this dataset.
     * @param {PatchedDataset} [patchedDataset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public datasetPartialUpdate(id: string, patchedDataset?: PatchedDataset, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).datasetPartialUpdate(id, patchedDataset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this dataset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public datasetRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).datasetRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this dataset.
     * @param {Dataset} dataset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public datasetUpdate(id: string, dataset: Dataset, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).datasetUpdate(id, dataset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
     * @param {string} id 
     * @param {Dataset} dataset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public datasetUploadCreate(id: string, dataset: Dataset, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).datasetUploadCreate(id, dataset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public datasetUploadRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).datasetUploadRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This is for users who want to download datasets from their Google COLAB. Parameter \"token\" must be set.
     * @param {string} id 
     * @param {Dataset} dataset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetApi
     */
    public datasetUploadUpdate(id: string, dataset: Dataset, options?: RawAxiosRequestConfig) {
        return DatasetApiFp(this.configuration).datasetUploadUpdate(id, dataset, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DatasetGroupApi - axios parameter creator
 * @export
 */
export const DatasetGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} courseId course_id required for finding submission_expiry
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupCourseSpecificDetailRetrieve: async (courseId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('datasetGroupCourseSpecificDetailRetrieve', 'courseId', courseId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('datasetGroupCourseSpecificDetailRetrieve', 'id', id)
            const localVarPath = `/api/DatasetGroup/{id}/CourseSpecificDetail/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['course_id'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} courseId all-assignments for course_id
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupCourseSpecificList: async (courseId: string, ordering?: string, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('datasetGroupCourseSpecificList', 'courseId', courseId)
            const localVarPath = `/api/DatasetGroup/CourseSpecific/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['course_id'] = courseId;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {Assignment} assignment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupCreate: async (assignment: Assignment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignment' is not null or undefined
            assertParamExists('datasetGroupCreate', 'assignment', assignment)
            const localVarPath = `/api/DatasetGroup/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupDestroy: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('datasetGroupDestroy', 'id', id)
            const localVarPath = `/api/DatasetGroup/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {Array<string>} [courses] 
         * @param {string} [createdBy] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupList: async (courses?: Array<string>, createdBy?: string, ordering?: string, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/DatasetGroup/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courses) {
                localVarQueryParameter['courses'] = courses;
            }

            if (createdBy !== undefined) {
                localVarQueryParameter['created_by'] = createdBy;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} id A UUID string identifying this assignment.
         * @param {PatchedAssignment} [patchedAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupPartialUpdate: async (id: string, patchedAssignment?: PatchedAssignment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('datasetGroupPartialUpdate', 'id', id)
            const localVarPath = `/api/DatasetGroup/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedAssignment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('datasetGroupRetrieve', 'id', id)
            const localVarPath = `/api/DatasetGroup/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} courseId 
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupTotalScoreRetrieve: async (courseId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('datasetGroupTotalScoreRetrieve', 'courseId', courseId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('datasetGroupTotalScoreRetrieve', 'id', id)
            const localVarPath = `/api/DatasetGroup/{id}/total_score/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['course_id'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} id A UUID string identifying this assignment.
         * @param {Assignment} assignment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupUpdate: async (id: string, assignment: Assignment, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('datasetGroupUpdate', 'id', id)
            // verify required parameter 'assignment' is not null or undefined
            assertParamExists('datasetGroupUpdate', 'assignment', assignment)
            const localVarPath = `/api/DatasetGroup/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatasetGroupApi - functional programming interface
 * @export
 */
export const DatasetGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatasetGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} courseId course_id required for finding submission_expiry
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetGroupCourseSpecificDetailRetrieve(courseId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentCourseSpecific>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetGroupCourseSpecificDetailRetrieve(courseId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetGroupApi.datasetGroupCourseSpecificDetailRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} courseId all-assignments for course_id
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetGroupCourseSpecificList(courseId: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssignmentCourseSpecific>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetGroupCourseSpecificList(courseId, ordering, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetGroupApi.datasetGroupCourseSpecificList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {Assignment} assignment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetGroupCreate(assignment: Assignment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assignment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetGroupCreate(assignment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetGroupApi.datasetGroupCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetGroupDestroy(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetGroupDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetGroupApi.datasetGroupDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {Array<string>} [courses] 
         * @param {string} [createdBy] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetGroupList(courses?: Array<string>, createdBy?: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Assignment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetGroupList(courses, createdBy, ordering, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetGroupApi.datasetGroupList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} id A UUID string identifying this assignment.
         * @param {PatchedAssignment} [patchedAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetGroupPartialUpdate(id: string, patchedAssignment?: PatchedAssignment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assignment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetGroupPartialUpdate(id, patchedAssignment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetGroupApi.datasetGroupPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetGroupRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assignment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetGroupRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetGroupApi.datasetGroupRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} courseId 
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetGroupTotalScoreRetrieve(courseId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetGroupTotalScoreRetrieve(courseId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetGroupApi.datasetGroupTotalScoreRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} id A UUID string identifying this assignment.
         * @param {Assignment} assignment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async datasetGroupUpdate(id: string, assignment: Assignment, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assignment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.datasetGroupUpdate(id, assignment, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DatasetGroupApi.datasetGroupUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DatasetGroupApi - factory interface
 * @export
 */
export const DatasetGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatasetGroupApiFp(configuration)
    return {
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} courseId course_id required for finding submission_expiry
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupCourseSpecificDetailRetrieve(courseId: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<AssignmentCourseSpecific> {
            return localVarFp.datasetGroupCourseSpecificDetailRetrieve(courseId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} courseId all-assignments for course_id
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupCourseSpecificList(courseId: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AssignmentCourseSpecific>> {
            return localVarFp.datasetGroupCourseSpecificList(courseId, ordering, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {Assignment} assignment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupCreate(assignment: Assignment, options?: RawAxiosRequestConfig): AxiosPromise<Assignment> {
            return localVarFp.datasetGroupCreate(assignment, options).then((request) => request(axios, basePath));
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupDestroy(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.datasetGroupDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {Array<string>} [courses] 
         * @param {string} [createdBy] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupList(courses?: Array<string>, createdBy?: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Assignment>> {
            return localVarFp.datasetGroupList(courses, createdBy, ordering, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} id A UUID string identifying this assignment.
         * @param {PatchedAssignment} [patchedAssignment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupPartialUpdate(id: string, patchedAssignment?: PatchedAssignment, options?: RawAxiosRequestConfig): AxiosPromise<Assignment> {
            return localVarFp.datasetGroupPartialUpdate(id, patchedAssignment, options).then((request) => request(axios, basePath));
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupRetrieve(id: string, options?: RawAxiosRequestConfig): AxiosPromise<Assignment> {
            return localVarFp.datasetGroupRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} courseId 
         * @param {string} id A UUID string identifying this assignment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupTotalScoreRetrieve(courseId: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.datasetGroupTotalScoreRetrieve(courseId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * Group of datasets. It is assignment without submission.
         * @param {string} id A UUID string identifying this assignment.
         * @param {Assignment} assignment 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        datasetGroupUpdate(id: string, assignment: Assignment, options?: RawAxiosRequestConfig): AxiosPromise<Assignment> {
            return localVarFp.datasetGroupUpdate(id, assignment, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DatasetGroupApi - object-oriented interface
 * @export
 * @class DatasetGroupApi
 * @extends {BaseAPI}
 */
export class DatasetGroupApi extends BaseAPI {
    /**
     * Group of datasets. It is assignment without submission.
     * @param {string} courseId course_id required for finding submission_expiry
     * @param {string} id A UUID string identifying this assignment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetGroupApi
     */
    public datasetGroupCourseSpecificDetailRetrieve(courseId: string, id: string, options?: RawAxiosRequestConfig) {
        return DatasetGroupApiFp(this.configuration).datasetGroupCourseSpecificDetailRetrieve(courseId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Group of datasets. It is assignment without submission.
     * @param {string} courseId all-assignments for course_id
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetGroupApi
     */
    public datasetGroupCourseSpecificList(courseId: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig) {
        return DatasetGroupApiFp(this.configuration).datasetGroupCourseSpecificList(courseId, ordering, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Group of datasets. It is assignment without submission.
     * @param {Assignment} assignment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetGroupApi
     */
    public datasetGroupCreate(assignment: Assignment, options?: RawAxiosRequestConfig) {
        return DatasetGroupApiFp(this.configuration).datasetGroupCreate(assignment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Group of datasets. It is assignment without submission.
     * @param {string} id A UUID string identifying this assignment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetGroupApi
     */
    public datasetGroupDestroy(id: string, options?: RawAxiosRequestConfig) {
        return DatasetGroupApiFp(this.configuration).datasetGroupDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Group of datasets. It is assignment without submission.
     * @param {Array<string>} [courses] 
     * @param {string} [createdBy] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetGroupApi
     */
    public datasetGroupList(courses?: Array<string>, createdBy?: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig) {
        return DatasetGroupApiFp(this.configuration).datasetGroupList(courses, createdBy, ordering, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Group of datasets. It is assignment without submission.
     * @param {string} id A UUID string identifying this assignment.
     * @param {PatchedAssignment} [patchedAssignment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetGroupApi
     */
    public datasetGroupPartialUpdate(id: string, patchedAssignment?: PatchedAssignment, options?: RawAxiosRequestConfig) {
        return DatasetGroupApiFp(this.configuration).datasetGroupPartialUpdate(id, patchedAssignment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Group of datasets. It is assignment without submission.
     * @param {string} id A UUID string identifying this assignment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetGroupApi
     */
    public datasetGroupRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return DatasetGroupApiFp(this.configuration).datasetGroupRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Group of datasets. It is assignment without submission.
     * @param {string} courseId 
     * @param {string} id A UUID string identifying this assignment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetGroupApi
     */
    public datasetGroupTotalScoreRetrieve(courseId: string, id: string, options?: RawAxiosRequestConfig) {
        return DatasetGroupApiFp(this.configuration).datasetGroupTotalScoreRetrieve(courseId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Group of datasets. It is assignment without submission.
     * @param {string} id A UUID string identifying this assignment.
     * @param {Assignment} assignment 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatasetGroupApi
     */
    public datasetGroupUpdate(id: string, assignment: Assignment, options?: RawAxiosRequestConfig) {
        return DatasetGroupApiFp(this.configuration).datasetGroupUpdate(id, assignment, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EditorListApi - axios parameter creator
 * @export
 */
export const EditorListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the list of editors who have permissions for the specified course. Add editors to the existing course.
         * @param {string} courseId 
         * @param {Editor} editor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editorListCreate: async (courseId: string, editor: Editor, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('editorListCreate', 'courseId', courseId)
            // verify required parameter 'editor' is not null or undefined
            assertParamExists('editorListCreate', 'editor', editor)
            const localVarPath = `/api/EditorList/{course_id}/`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editor, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of editors who have permissions for the specified course. Add editors to the existing course.
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editorListDestroy: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('editorListDestroy', 'courseId', courseId)
            const localVarPath = `/api/EditorList/{course_id}/`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of editors who have permissions for the specified course. Add editors to the existing course.
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editorListRetrieve: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('editorListRetrieve', 'courseId', courseId)
            const localVarPath = `/api/EditorList/{course_id}/`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EditorListApi - functional programming interface
 * @export
 */
export const EditorListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EditorListApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the list of editors who have permissions for the specified course. Add editors to the existing course.
         * @param {string} courseId 
         * @param {Editor} editor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editorListCreate(courseId: string, editor: Editor, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Editor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editorListCreate(courseId, editor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EditorListApi.editorListCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the list of editors who have permissions for the specified course. Add editors to the existing course.
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editorListDestroy(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editorListDestroy(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EditorListApi.editorListDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the list of editors who have permissions for the specified course. Add editors to the existing course.
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editorListRetrieve(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Editor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editorListRetrieve(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EditorListApi.editorListRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EditorListApi - factory interface
 * @export
 */
export const EditorListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EditorListApiFp(configuration)
    return {
        /**
         * Get the list of editors who have permissions for the specified course. Add editors to the existing course.
         * @param {string} courseId 
         * @param {Editor} editor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editorListCreate(courseId: string, editor: Editor, options?: RawAxiosRequestConfig): AxiosPromise<Editor> {
            return localVarFp.editorListCreate(courseId, editor, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of editors who have permissions for the specified course. Add editors to the existing course.
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editorListDestroy(courseId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.editorListDestroy(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of editors who have permissions for the specified course. Add editors to the existing course.
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editorListRetrieve(courseId: string, options?: RawAxiosRequestConfig): AxiosPromise<Editor> {
            return localVarFp.editorListRetrieve(courseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EditorListApi - object-oriented interface
 * @export
 * @class EditorListApi
 * @extends {BaseAPI}
 */
export class EditorListApi extends BaseAPI {
    /**
     * Get the list of editors who have permissions for the specified course. Add editors to the existing course.
     * @param {string} courseId 
     * @param {Editor} editor 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditorListApi
     */
    public editorListCreate(courseId: string, editor: Editor, options?: RawAxiosRequestConfig) {
        return EditorListApiFp(this.configuration).editorListCreate(courseId, editor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of editors who have permissions for the specified course. Add editors to the existing course.
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditorListApi
     */
    public editorListDestroy(courseId: string, options?: RawAxiosRequestConfig) {
        return EditorListApiFp(this.configuration).editorListDestroy(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of editors who have permissions for the specified course. Add editors to the existing course.
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditorListApi
     */
    public editorListRetrieve(courseId: string, options?: RawAxiosRequestConfig) {
        return EditorListApiFp(this.configuration).editorListRetrieve(courseId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EvaluationApi - axios parameter creator
 * @export
 */
export const EvaluationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This must be edited only by administrative users to give the datas to the scoring server.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {EvaluationListStatusEnum} [status] * &#x60;CO&#x60; - Completed * &#x60;CA&#x60; - Canceled * &#x60;SC&#x60; - Scheduled * &#x60;PD&#x60; - Pending * &#x60;EX&#x60; - Executing * &#x60;IF&#x60; - Invalidformat * &#x60;IE&#x60; - Internalerror * &#x60;WR&#x60; - Wrong * &#x60;CR&#x60; - Correct
         * @param {string} [submission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evaluationList: async (limit?: number, offset?: number, ordering?: string, status?: EvaluationListStatusEnum, submission?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Evaluation/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (submission !== undefined) {
                localVarQueryParameter['submission'] = submission;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EvaluationApi - functional programming interface
 * @export
 */
export const EvaluationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EvaluationApiAxiosParamCreator(configuration)
    return {
        /**
         * This must be edited only by administrative users to give the datas to the scoring server.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {EvaluationListStatusEnum} [status] * &#x60;CO&#x60; - Completed * &#x60;CA&#x60; - Canceled * &#x60;SC&#x60; - Scheduled * &#x60;PD&#x60; - Pending * &#x60;EX&#x60; - Executing * &#x60;IF&#x60; - Invalidformat * &#x60;IE&#x60; - Internalerror * &#x60;WR&#x60; - Wrong * &#x60;CR&#x60; - Correct
         * @param {string} [submission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async evaluationList(limit?: number, offset?: number, ordering?: string, status?: EvaluationListStatusEnum, submission?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedEvaluationList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.evaluationList(limit, offset, ordering, status, submission, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EvaluationApi.evaluationList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EvaluationApi - factory interface
 * @export
 */
export const EvaluationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EvaluationApiFp(configuration)
    return {
        /**
         * This must be edited only by administrative users to give the datas to the scoring server.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {EvaluationListStatusEnum} [status] * &#x60;CO&#x60; - Completed * &#x60;CA&#x60; - Canceled * &#x60;SC&#x60; - Scheduled * &#x60;PD&#x60; - Pending * &#x60;EX&#x60; - Executing * &#x60;IF&#x60; - Invalidformat * &#x60;IE&#x60; - Internalerror * &#x60;WR&#x60; - Wrong * &#x60;CR&#x60; - Correct
         * @param {string} [submission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        evaluationList(limit?: number, offset?: number, ordering?: string, status?: EvaluationListStatusEnum, submission?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedEvaluationList> {
            return localVarFp.evaluationList(limit, offset, ordering, status, submission, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EvaluationApi - object-oriented interface
 * @export
 * @class EvaluationApi
 * @extends {BaseAPI}
 */
export class EvaluationApi extends BaseAPI {
    /**
     * This must be edited only by administrative users to give the datas to the scoring server.
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {EvaluationListStatusEnum} [status] * &#x60;CO&#x60; - Completed * &#x60;CA&#x60; - Canceled * &#x60;SC&#x60; - Scheduled * &#x60;PD&#x60; - Pending * &#x60;EX&#x60; - Executing * &#x60;IF&#x60; - Invalidformat * &#x60;IE&#x60; - Internalerror * &#x60;WR&#x60; - Wrong * &#x60;CR&#x60; - Correct
     * @param {string} [submission] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationApi
     */
    public evaluationList(limit?: number, offset?: number, ordering?: string, status?: EvaluationListStatusEnum, submission?: string, options?: RawAxiosRequestConfig) {
        return EvaluationApiFp(this.configuration).evaluationList(limit, offset, ordering, status, submission, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const EvaluationListStatusEnum = {
    Ca: 'CA',
    Co: 'CO',
    Cr: 'CR',
    Ex: 'EX',
    Ie: 'IE',
    If: 'IF',
    Pd: 'PD',
    Sc: 'SC',
    Wr: 'WR'
} as const;
export type EvaluationListStatusEnum = typeof EvaluationListStatusEnum[keyof typeof EvaluationListStatusEnum];


/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventList: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('eventList', 'courseId', courseId)
            const localVarPath = `/api/Event/{course_id}/`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventList(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Event>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventList(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EventApi.eventList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventApiFp(configuration)
    return {
        /**
         * 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventList(courseId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Event>> {
            return localVarFp.eventList(courseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     * 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public eventList(courseId: string, options?: RawAxiosRequestConfig) {
        return EventApiFp(this.configuration).eventList(courseId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ForumApi - axios parameter creator
 * @export
 */
export const ForumApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [parentRecord] 
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumCommentList: async (limit?: number, offset?: number, ordering?: string, parentRecord?: string, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Forum/Comment/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (parentRecord !== undefined) {
                localVarQueryParameter['parent_record'] = parentRecord;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ForumFileUpload} forumFileUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumFileUploadCreate: async (id: string, forumFileUpload: ForumFileUpload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumForumFileUploadCreate', 'id', id)
            // verify required parameter 'forumFileUpload' is not null or undefined
            assertParamExists('forumForumFileUploadCreate', 'forumFileUpload', forumFileUpload)
            const localVarPath = `/api/Forum/ForumFileUpload/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumFileUpload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumFileUploadDestroy: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumForumFileUploadDestroy', 'id', id)
            const localVarPath = `/api/Forum/ForumFileUpload/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumSignedURLRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Forum/ForumSignedURL/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumUpvoteUsersList: async (forumId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumUpvoteUsersList', 'forumId', forumId)
            const localVarPath = `/api/Forum/ForumUpvoteUsers/{forum_id}/`
                .replace(`{${"forum_id"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} forumId 
         * @param {ForumUserStatus} forumUserStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumUserStatusUpdate: async (forumId: string, forumUserStatus: ForumUserStatus, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumUserStatusUpdate', 'forumId', forumId)
            // verify required parameter 'forumUserStatus' is not null or undefined
            assertParamExists('forumForumUserStatusUpdate', 'forumUserStatus', forumUserStatus)
            const localVarPath = `/api/Forum/ForumUserStatus/{forum_id}/`
                .replace(`{${"forum_id"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumUserStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set/unset an entry as an announcement.
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordAnnouncementDestroy: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumRecordAnnouncementDestroy', 'id', id)
            const localVarPath = `/api/Forum/Record/{id}/announcement/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Set/unset an entry as an announcement.
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordAnnouncementUpdate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumRecordAnnouncementUpdate', 'id', id)
            const localVarPath = `/api/Forum/Record/{id}/announcement/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [course] 
         * @param {string} [fullTextSearch] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {boolean} [pinnedOnly] 
         * @param {boolean} [scheduledOnes] 
         * @param {boolean} [unreadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordAnnouncementsList: async (course?: string, fullTextSearch?: string, limit?: number, offset?: number, ordering?: string, pinnedOnly?: boolean, scheduledOnes?: boolean, unreadOnly?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Forum/Record/announcements/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (course !== undefined) {
                localVarQueryParameter['course'] = course;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['full_text_search'] = fullTextSearch;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (pinnedOnly !== undefined) {
                localVarQueryParameter['pinned_only'] = pinnedOnly;
            }

            if (scheduledOnes !== undefined) {
                localVarQueryParameter['scheduled_ones'] = scheduledOnes;
            }

            if (unreadOnly !== undefined) {
                localVarQueryParameter['unread_only'] = unreadOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForumRecordDetailed} forumRecordDetailed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordCreate: async (forumRecordDetailed: ForumRecordDetailed, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumRecordDetailed' is not null or undefined
            assertParamExists('forumRecordCreate', 'forumRecordDetailed', forumRecordDetailed)
            const localVarPath = `/api/Forum/Record/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumRecordDetailed, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordDestroy: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumRecordDestroy', 'id', id)
            const localVarPath = `/api/Forum/Record/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get forums, along with posts.  Keyword Arguments:     user_id: filter forums where certain user is involved.     updated_by: filter forums by certain date.  For getting course specific forum -> course as query param should be passed
         * @param {string} [course] 
         * @param {string} [fullTextSearch] 
         * @param {boolean} [isAnnouncement] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {boolean} [pinnedOnly] 
         * @param {boolean} [unreadOnly] 
         * @param {number} [updatedBy] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordList: async (course?: string, fullTextSearch?: string, isAnnouncement?: boolean, limit?: number, offset?: number, ordering?: string, pinnedOnly?: boolean, unreadOnly?: boolean, updatedBy?: number, userId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Forum/Record/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (course !== undefined) {
                localVarQueryParameter['course'] = course;
            }

            if (fullTextSearch !== undefined) {
                localVarQueryParameter['full_text_search'] = fullTextSearch;
            }

            if (isAnnouncement !== undefined) {
                localVarQueryParameter['is_announcement'] = isAnnouncement;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (pinnedOnly !== undefined) {
                localVarQueryParameter['pinned_only'] = pinnedOnly;
            }

            if (unreadOnly !== undefined) {
                localVarQueryParameter['unread_only'] = unreadOnly;
            }

            if (updatedBy !== undefined) {
                localVarQueryParameter['updated_by'] = updatedBy;
            }

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {PatchedForumRecordDetailed} [patchedForumRecordDetailed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordPartialUpdate: async (id: string, patchedForumRecordDetailed?: PatchedForumRecordDetailed, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumRecordPartialUpdate', 'id', id)
            const localVarPath = `/api/Forum/Record/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedForumRecordDetailed, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumRecordRetrieve', 'id', id)
            const localVarPath = `/api/Forum/Record/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {ForumRecordDetailed} forumRecordDetailed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordUpdate: async (id: string, forumRecordDetailed: ForumRecordDetailed, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumRecordUpdate', 'id', id)
            // verify required parameter 'forumRecordDetailed' is not null or undefined
            assertParamExists('forumRecordUpdate', 'forumRecordDetailed', forumRecordDetailed)
            const localVarPath = `/api/Forum/Record/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumRecordDetailed, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordUpvoteDestroy: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumRecordUpvoteDestroy', 'id', id)
            const localVarPath = `/api/Forum/Record/{id}/upvote/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordUpvoteUpdate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumRecordUpvoteUpdate', 'id', id)
            const localVarPath = `/api/Forum/Record/{id}/upvote/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Finalize a scheduled announcement, i.e., send a notification email and mark is_scheduled_announcement as False.  Designated as a Cloud Tasks Target handler for finalizing scheduled announcements.
         * @param {ScheduledAnnouncementsFinalize} scheduledAnnouncementsFinalize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumScheduledAnnouncementsFinalizeCreate: async (scheduledAnnouncementsFinalize: ScheduledAnnouncementsFinalize, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduledAnnouncementsFinalize' is not null or undefined
            assertParamExists('forumScheduledAnnouncementsFinalizeCreate', 'scheduledAnnouncementsFinalize', scheduledAnnouncementsFinalize)
            const localVarPath = `/api/Forum/scheduled-announcements/finalize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduledAnnouncementsFinalize, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [course] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumStatsList: async (course?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Forum/stats/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (course !== undefined) {
                localVarQueryParameter['course'] = course;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumTemplatesRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Forum/Templates/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumApi - functional programming interface
 * @export
 */
export const ForumApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [parentRecord] 
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumCommentList(limit?: number, offset?: number, ordering?: string, parentRecord?: string, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedForumRecordDetailedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumCommentList(limit, offset, ordering, parentRecord, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumCommentList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ForumFileUpload} forumFileUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumFileUploadCreate(id: string, forumFileUpload: ForumFileUpload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumFileUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumFileUploadCreate(id, forumFileUpload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumForumFileUploadCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumFileUploadDestroy(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumFileUploadDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumForumFileUploadDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumSignedURLRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedURL>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumSignedURLRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumForumSignedURLRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumUpvoteUsersList(forumId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumUpvoteUsersList(forumId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumForumUpvoteUsersList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} forumId 
         * @param {ForumUserStatus} forumUserStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumUserStatusUpdate(forumId: string, forumUserStatus: ForumUserStatus, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumUserStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumUserStatusUpdate(forumId, forumUserStatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumForumUserStatusUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Set/unset an entry as an announcement.
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumRecordAnnouncementDestroy(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumRecordAnnouncementDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumRecordAnnouncementDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Set/unset an entry as an announcement.
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumRecordAnnouncementUpdate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumRecordAnnouncementUpdate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumRecordAnnouncementUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [course] 
         * @param {string} [fullTextSearch] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {boolean} [pinnedOnly] 
         * @param {boolean} [scheduledOnes] 
         * @param {boolean} [unreadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumRecordAnnouncementsList(course?: string, fullTextSearch?: string, limit?: number, offset?: number, ordering?: string, pinnedOnly?: boolean, scheduledOnes?: boolean, unreadOnly?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedForumRecordListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumRecordAnnouncementsList(course, fullTextSearch, limit, offset, ordering, pinnedOnly, scheduledOnes, unreadOnly, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumRecordAnnouncementsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ForumRecordDetailed} forumRecordDetailed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumRecordCreate(forumRecordDetailed: ForumRecordDetailed, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumRecordDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumRecordCreate(forumRecordDetailed, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumRecordCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumRecordDestroy(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumRecordDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumRecordDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get forums, along with posts.  Keyword Arguments:     user_id: filter forums where certain user is involved.     updated_by: filter forums by certain date.  For getting course specific forum -> course as query param should be passed
         * @param {string} [course] 
         * @param {string} [fullTextSearch] 
         * @param {boolean} [isAnnouncement] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {boolean} [pinnedOnly] 
         * @param {boolean} [unreadOnly] 
         * @param {number} [updatedBy] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumRecordList(course?: string, fullTextSearch?: string, isAnnouncement?: boolean, limit?: number, offset?: number, ordering?: string, pinnedOnly?: boolean, unreadOnly?: boolean, updatedBy?: number, userId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedForumRecordListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumRecordList(course, fullTextSearch, isAnnouncement, limit, offset, ordering, pinnedOnly, unreadOnly, updatedBy, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumRecordList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {PatchedForumRecordDetailed} [patchedForumRecordDetailed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumRecordPartialUpdate(id: string, patchedForumRecordDetailed?: PatchedForumRecordDetailed, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumRecordDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumRecordPartialUpdate(id, patchedForumRecordDetailed, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumRecordPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumRecordRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumRecordDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumRecordRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumRecordRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {ForumRecordDetailed} forumRecordDetailed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumRecordUpdate(id: string, forumRecordDetailed: ForumRecordDetailed, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumRecordDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumRecordUpdate(id, forumRecordDetailed, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumRecordUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumRecordUpvoteDestroy(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumRecordUpvoteDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumRecordUpvoteDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumRecordUpvoteUpdate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumRecordUpvoteUpdate(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumRecordUpvoteUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Finalize a scheduled announcement, i.e., send a notification email and mark is_scheduled_announcement as False.  Designated as a Cloud Tasks Target handler for finalizing scheduled announcements.
         * @param {ScheduledAnnouncementsFinalize} scheduledAnnouncementsFinalize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumScheduledAnnouncementsFinalizeCreate(scheduledAnnouncementsFinalize: ScheduledAnnouncementsFinalize, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledAnnouncementsFinalize>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumScheduledAnnouncementsFinalizeCreate(scheduledAnnouncementsFinalize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumScheduledAnnouncementsFinalizeCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [course] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumStatsList(course?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumStats>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumStatsList(course, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumStatsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumTemplatesRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumTemplatesRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ForumApi.forumTemplatesRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ForumApi - factory interface
 * @export
 */
export const ForumApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [parentRecord] 
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumCommentList(limit?: number, offset?: number, ordering?: string, parentRecord?: string, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedForumRecordDetailedList> {
            return localVarFp.forumCommentList(limit, offset, ordering, parentRecord, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ForumFileUpload} forumFileUpload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumFileUploadCreate(id: string, forumFileUpload: ForumFileUpload, options?: RawAxiosRequestConfig): AxiosPromise<ForumFileUpload> {
            return localVarFp.forumForumFileUploadCreate(id, forumFileUpload, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumFileUploadDestroy(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumFileUploadDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumSignedURLRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<SignedURL> {
            return localVarFp.forumForumSignedURLRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumUpvoteUsersList(forumId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<PublicUser>> {
            return localVarFp.forumForumUpvoteUsersList(forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} forumId 
         * @param {ForumUserStatus} forumUserStatus 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumUserStatusUpdate(forumId: string, forumUserStatus: ForumUserStatus, options?: RawAxiosRequestConfig): AxiosPromise<ForumUserStatus> {
            return localVarFp.forumForumUserStatusUpdate(forumId, forumUserStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * Set/unset an entry as an announcement.
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordAnnouncementDestroy(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumRecordAnnouncementDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Set/unset an entry as an announcement.
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordAnnouncementUpdate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumRecordAnnouncementUpdate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [course] 
         * @param {string} [fullTextSearch] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {boolean} [pinnedOnly] 
         * @param {boolean} [scheduledOnes] 
         * @param {boolean} [unreadOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordAnnouncementsList(course?: string, fullTextSearch?: string, limit?: number, offset?: number, ordering?: string, pinnedOnly?: boolean, scheduledOnes?: boolean, unreadOnly?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedForumRecordListList> {
            return localVarFp.forumRecordAnnouncementsList(course, fullTextSearch, limit, offset, ordering, pinnedOnly, scheduledOnes, unreadOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumRecordDetailed} forumRecordDetailed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordCreate(forumRecordDetailed: ForumRecordDetailed, options?: RawAxiosRequestConfig): AxiosPromise<ForumRecordDetailed> {
            return localVarFp.forumRecordCreate(forumRecordDetailed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordDestroy(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumRecordDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get forums, along with posts.  Keyword Arguments:     user_id: filter forums where certain user is involved.     updated_by: filter forums by certain date.  For getting course specific forum -> course as query param should be passed
         * @param {string} [course] 
         * @param {string} [fullTextSearch] 
         * @param {boolean} [isAnnouncement] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {boolean} [pinnedOnly] 
         * @param {boolean} [unreadOnly] 
         * @param {number} [updatedBy] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordList(course?: string, fullTextSearch?: string, isAnnouncement?: boolean, limit?: number, offset?: number, ordering?: string, pinnedOnly?: boolean, unreadOnly?: boolean, updatedBy?: number, userId?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedForumRecordListList> {
            return localVarFp.forumRecordList(course, fullTextSearch, isAnnouncement, limit, offset, ordering, pinnedOnly, unreadOnly, updatedBy, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {PatchedForumRecordDetailed} [patchedForumRecordDetailed] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordPartialUpdate(id: string, patchedForumRecordDetailed?: PatchedForumRecordDetailed, options?: RawAxiosRequestConfig): AxiosPromise<ForumRecordDetailed> {
            return localVarFp.forumRecordPartialUpdate(id, patchedForumRecordDetailed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordRetrieve(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ForumRecordDetailed> {
            return localVarFp.forumRecordRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {ForumRecordDetailed} forumRecordDetailed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordUpdate(id: string, forumRecordDetailed: ForumRecordDetailed, options?: RawAxiosRequestConfig): AxiosPromise<ForumRecordDetailed> {
            return localVarFp.forumRecordUpdate(id, forumRecordDetailed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordUpvoteDestroy(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumRecordUpvoteDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this forum record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumRecordUpvoteUpdate(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumRecordUpvoteUpdate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Finalize a scheduled announcement, i.e., send a notification email and mark is_scheduled_announcement as False.  Designated as a Cloud Tasks Target handler for finalizing scheduled announcements.
         * @param {ScheduledAnnouncementsFinalize} scheduledAnnouncementsFinalize 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumScheduledAnnouncementsFinalizeCreate(scheduledAnnouncementsFinalize: ScheduledAnnouncementsFinalize, options?: RawAxiosRequestConfig): AxiosPromise<ScheduledAnnouncementsFinalize> {
            return localVarFp.forumScheduledAnnouncementsFinalizeCreate(scheduledAnnouncementsFinalize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [course] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumStatsList(course?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ForumStats>> {
            return localVarFp.forumStatsList(course, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumTemplatesRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<ForumTemplate> {
            return localVarFp.forumTemplatesRetrieve(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForumApi - object-oriented interface
 * @export
 * @class ForumApi
 * @extends {BaseAPI}
 */
export class ForumApi extends BaseAPI {
    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [parentRecord] 
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumCommentList(limit?: number, offset?: number, ordering?: string, parentRecord?: string, search?: string, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumCommentList(limit, offset, ordering, parentRecord, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ForumFileUpload} forumFileUpload 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumForumFileUploadCreate(id: string, forumFileUpload: ForumFileUpload, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumForumFileUploadCreate(id, forumFileUpload, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumForumFileUploadDestroy(id: string, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumForumFileUploadDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumForumSignedURLRetrieve(options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumForumSignedURLRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} forumId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumForumUpvoteUsersList(forumId: string, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumForumUpvoteUsersList(forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} forumId 
     * @param {ForumUserStatus} forumUserStatus 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumForumUserStatusUpdate(forumId: string, forumUserStatus: ForumUserStatus, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumForumUserStatusUpdate(forumId, forumUserStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set/unset an entry as an announcement.
     * @param {string} id A UUID string identifying this forum record.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumRecordAnnouncementDestroy(id: string, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumRecordAnnouncementDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Set/unset an entry as an announcement.
     * @param {string} id A UUID string identifying this forum record.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumRecordAnnouncementUpdate(id: string, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumRecordAnnouncementUpdate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [course] 
     * @param {string} [fullTextSearch] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {boolean} [pinnedOnly] 
     * @param {boolean} [scheduledOnes] 
     * @param {boolean} [unreadOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumRecordAnnouncementsList(course?: string, fullTextSearch?: string, limit?: number, offset?: number, ordering?: string, pinnedOnly?: boolean, scheduledOnes?: boolean, unreadOnly?: boolean, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumRecordAnnouncementsList(course, fullTextSearch, limit, offset, ordering, pinnedOnly, scheduledOnes, unreadOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumRecordDetailed} forumRecordDetailed 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumRecordCreate(forumRecordDetailed: ForumRecordDetailed, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumRecordCreate(forumRecordDetailed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this forum record.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumRecordDestroy(id: string, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumRecordDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get forums, along with posts.  Keyword Arguments:     user_id: filter forums where certain user is involved.     updated_by: filter forums by certain date.  For getting course specific forum -> course as query param should be passed
     * @param {string} [course] 
     * @param {string} [fullTextSearch] 
     * @param {boolean} [isAnnouncement] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {boolean} [pinnedOnly] 
     * @param {boolean} [unreadOnly] 
     * @param {number} [updatedBy] 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumRecordList(course?: string, fullTextSearch?: string, isAnnouncement?: boolean, limit?: number, offset?: number, ordering?: string, pinnedOnly?: boolean, unreadOnly?: boolean, updatedBy?: number, userId?: string, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumRecordList(course, fullTextSearch, isAnnouncement, limit, offset, ordering, pinnedOnly, unreadOnly, updatedBy, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this forum record.
     * @param {PatchedForumRecordDetailed} [patchedForumRecordDetailed] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumRecordPartialUpdate(id: string, patchedForumRecordDetailed?: PatchedForumRecordDetailed, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumRecordPartialUpdate(id, patchedForumRecordDetailed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this forum record.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumRecordRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumRecordRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this forum record.
     * @param {ForumRecordDetailed} forumRecordDetailed 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumRecordUpdate(id: string, forumRecordDetailed: ForumRecordDetailed, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumRecordUpdate(id, forumRecordDetailed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this forum record.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumRecordUpvoteDestroy(id: string, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumRecordUpvoteDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this forum record.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumRecordUpvoteUpdate(id: string, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumRecordUpvoteUpdate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Finalize a scheduled announcement, i.e., send a notification email and mark is_scheduled_announcement as False.  Designated as a Cloud Tasks Target handler for finalizing scheduled announcements.
     * @param {ScheduledAnnouncementsFinalize} scheduledAnnouncementsFinalize 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumScheduledAnnouncementsFinalizeCreate(scheduledAnnouncementsFinalize: ScheduledAnnouncementsFinalize, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumScheduledAnnouncementsFinalizeCreate(scheduledAnnouncementsFinalize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [course] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumStatsList(course?: string, options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumStatsList(course, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumApi
     */
    public forumTemplatesRetrieve(options?: RawAxiosRequestConfig) {
        return ForumApiFp(this.configuration).forumTemplatesRetrieve(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LeaderboardApi - axios parameter creator
 * @export
 */
export const LeaderboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the leaderboard of the specified assignment. This part is terrible in terms of performance...
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaderboardList: async (assignmentId: string, courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('leaderboardList', 'assignmentId', assignmentId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('leaderboardList', 'courseId', courseId)
            const localVarPath = `/api/Leaderboard/{assignment_id}/{course_id}`
                .replace(`{${"assignment_id"}}`, encodeURIComponent(String(assignmentId)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeaderboardApi - functional programming interface
 * @export
 */
export const LeaderboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeaderboardApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the leaderboard of the specified assignment. This part is terrible in terms of performance...
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leaderboardList(assignmentId: string, courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubmissionListAdmin>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leaderboardList(assignmentId, courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LeaderboardApi.leaderboardList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LeaderboardApi - factory interface
 * @export
 */
export const LeaderboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeaderboardApiFp(configuration)
    return {
        /**
         * Get the leaderboard of the specified assignment. This part is terrible in terms of performance...
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leaderboardList(assignmentId: string, courseId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<SubmissionListAdmin>> {
            return localVarFp.leaderboardList(assignmentId, courseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeaderboardApi - object-oriented interface
 * @export
 * @class LeaderboardApi
 * @extends {BaseAPI}
 */
export class LeaderboardApi extends BaseAPI {
    /**
     * Get the leaderboard of the specified assignment. This part is terrible in terms of performance...
     * @param {string} assignmentId 
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeaderboardApi
     */
    public leaderboardList(assignmentId: string, courseId: string, options?: RawAxiosRequestConfig) {
        return LeaderboardApiFp(this.configuration).leaderboardList(assignmentId, courseId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PygradeApi - axios parameter creator
 * @export
 */
export const PygradeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} testId 
         * @param {Answer} [answer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeExamsAnswersCreate: async (testId: string, answer?: Answer, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('pygradeExamsAnswersCreate', 'testId', testId)
            const localVarPath = `/api/pygrade/exams/{testId}/answers`
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(answer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeExamsList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pygrade/exams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} testId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeExamsProblemsList: async (testId: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('pygradeExamsProblemsList', 'testId', testId)
            const localVarPath = `/api/pygrade/exams/{testId}/problems`
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} problemId 
         * @param {string} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeExamsProblemsRetrieve: async (problemId: string, testId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'problemId' is not null or undefined
            assertParamExists('pygradeExamsProblemsRetrieve', 'problemId', problemId)
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('pygradeExamsProblemsRetrieve', 'testId', testId)
            const localVarPath = `/api/pygrade/exams/{testId}/problems/{problemId}`
                .replace(`{${"problemId"}}`, encodeURIComponent(String(problemId)))
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeExamsRetrieve: async (testId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('pygradeExamsRetrieve', 'testId', testId)
            const localVarPath = `/api/pygrade/exams/{testId}`
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeTrainingsList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pygrade/trainings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} testId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeTrainingsProblemsList: async (testId: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('pygradeTrainingsProblemsList', 'testId', testId)
            const localVarPath = `/api/pygrade/trainings/{testId}/problems`
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} problemId 
         * @param {string} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeTrainingsProblemsRetrieve: async (problemId: string, testId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'problemId' is not null or undefined
            assertParamExists('pygradeTrainingsProblemsRetrieve', 'problemId', problemId)
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('pygradeTrainingsProblemsRetrieve', 'testId', testId)
            const localVarPath = `/api/pygrade/trainings/{testId}/problems/{problemId}`
                .replace(`{${"problemId"}}`, encodeURIComponent(String(problemId)))
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeTrainingsRetrieve: async (testId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testId' is not null or undefined
            assertParamExists('pygradeTrainingsRetrieve', 'testId', testId)
            const localVarPath = `/api/pygrade/trainings/{testId}`
                .replace(`{${"testId"}}`, encodeURIComponent(String(testId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PygradeApi - functional programming interface
 * @export
 */
export const PygradeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PygradeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} testId 
         * @param {Answer} [answer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pygradeExamsAnswersCreate(testId: string, answer?: Answer, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Answer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pygradeExamsAnswersCreate(testId, answer, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PygradeApi.pygradeExamsAnswersCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pygradeExamsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedExamList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pygradeExamsList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PygradeApi.pygradeExamsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} testId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pygradeExamsProblemsList(testId: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProblemList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pygradeExamsProblemsList(testId, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PygradeApi.pygradeExamsProblemsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} problemId 
         * @param {string} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pygradeExamsProblemsRetrieve(problemId: string, testId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Problem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pygradeExamsProblemsRetrieve(problemId, testId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PygradeApi.pygradeExamsProblemsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pygradeExamsRetrieve(testId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExamDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pygradeExamsRetrieve(testId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PygradeApi.pygradeExamsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pygradeTrainingsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedTrainingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pygradeTrainingsList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PygradeApi.pygradeTrainingsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} testId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pygradeTrainingsProblemsList(testId: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProblemList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pygradeTrainingsProblemsList(testId, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PygradeApi.pygradeTrainingsProblemsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} problemId 
         * @param {string} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pygradeTrainingsProblemsRetrieve(problemId: string, testId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Problem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pygradeTrainingsProblemsRetrieve(problemId, testId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PygradeApi.pygradeTrainingsProblemsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pygradeTrainingsRetrieve(testId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrainingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pygradeTrainingsRetrieve(testId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PygradeApi.pygradeTrainingsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PygradeApi - factory interface
 * @export
 */
export const PygradeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PygradeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} testId 
         * @param {Answer} [answer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeExamsAnswersCreate(testId: string, answer?: Answer, options?: RawAxiosRequestConfig): AxiosPromise<Answer> {
            return localVarFp.pygradeExamsAnswersCreate(testId, answer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeExamsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedExamList> {
            return localVarFp.pygradeExamsList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} testId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeExamsProblemsList(testId: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedProblemList> {
            return localVarFp.pygradeExamsProblemsList(testId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} problemId 
         * @param {string} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeExamsProblemsRetrieve(problemId: string, testId: string, options?: RawAxiosRequestConfig): AxiosPromise<Problem> {
            return localVarFp.pygradeExamsProblemsRetrieve(problemId, testId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeExamsRetrieve(testId: string, options?: RawAxiosRequestConfig): AxiosPromise<ExamDetail> {
            return localVarFp.pygradeExamsRetrieve(testId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeTrainingsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedTrainingList> {
            return localVarFp.pygradeTrainingsList(limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} testId 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeTrainingsProblemsList(testId: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedProblemList> {
            return localVarFp.pygradeTrainingsProblemsList(testId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} problemId 
         * @param {string} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeTrainingsProblemsRetrieve(problemId: string, testId: string, options?: RawAxiosRequestConfig): AxiosPromise<Problem> {
            return localVarFp.pygradeTrainingsProblemsRetrieve(problemId, testId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} testId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pygradeTrainingsRetrieve(testId: string, options?: RawAxiosRequestConfig): AxiosPromise<TrainingDetail> {
            return localVarFp.pygradeTrainingsRetrieve(testId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PygradeApi - object-oriented interface
 * @export
 * @class PygradeApi
 * @extends {BaseAPI}
 */
export class PygradeApi extends BaseAPI {
    /**
     * 
     * @param {string} testId 
     * @param {Answer} [answer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PygradeApi
     */
    public pygradeExamsAnswersCreate(testId: string, answer?: Answer, options?: RawAxiosRequestConfig) {
        return PygradeApiFp(this.configuration).pygradeExamsAnswersCreate(testId, answer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PygradeApi
     */
    public pygradeExamsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return PygradeApiFp(this.configuration).pygradeExamsList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} testId 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PygradeApi
     */
    public pygradeExamsProblemsList(testId: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return PygradeApiFp(this.configuration).pygradeExamsProblemsList(testId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} problemId 
     * @param {string} testId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PygradeApi
     */
    public pygradeExamsProblemsRetrieve(problemId: string, testId: string, options?: RawAxiosRequestConfig) {
        return PygradeApiFp(this.configuration).pygradeExamsProblemsRetrieve(problemId, testId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} testId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PygradeApi
     */
    public pygradeExamsRetrieve(testId: string, options?: RawAxiosRequestConfig) {
        return PygradeApiFp(this.configuration).pygradeExamsRetrieve(testId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PygradeApi
     */
    public pygradeTrainingsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return PygradeApiFp(this.configuration).pygradeTrainingsList(limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} testId 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PygradeApi
     */
    public pygradeTrainingsProblemsList(testId: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig) {
        return PygradeApiFp(this.configuration).pygradeTrainingsProblemsList(testId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} problemId 
     * @param {string} testId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PygradeApi
     */
    public pygradeTrainingsProblemsRetrieve(problemId: string, testId: string, options?: RawAxiosRequestConfig) {
        return PygradeApiFp(this.configuration).pygradeTrainingsProblemsRetrieve(problemId, testId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} testId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PygradeApi
     */
    public pygradeTrainingsRetrieve(testId: string, options?: RawAxiosRequestConfig) {
        return PygradeApiFp(this.configuration).pygradeTrainingsRetrieve(testId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * QuizApi - axios parameter creator
 * @export
 */
export const QuizApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} courseId course_id required for finding submission_expiry
         * @param {number} id A unique integer value identifying this quiz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizCourseSpecificDetailRetrieve: async (courseId: string, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('quizCourseSpecificDetailRetrieve', 'courseId', courseId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('quizCourseSpecificDetailRetrieve', 'id', id)
            const localVarPath = `/api/Quiz/{id}/CourseSpecificDetail/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['course_id'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId all-quizzes for course_id
         * @param {boolean} [hideSubmitted] only return non-submitted quizzes
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizCourseSpecificList: async (courseId: string, hideSubmitted?: boolean, ordering?: string, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('quizCourseSpecificList', 'courseId', courseId)
            const localVarPath = `/api/Quiz/CourseSpecific/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['course_id'] = courseId;
            }

            if (hideSubmitted !== undefined) {
                localVarQueryParameter['hide_submitted'] = hideSubmitted;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [courses] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizList: async (courses?: Array<string>, ordering?: string, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Quiz/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courses) {
                localVarQueryParameter['courses'] = courses;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId course_id required for finding quizcourse
         * @param {number} id A unique integer value identifying this quiz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizQuizContentRetrieve: async (courseId: string, id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('quizQuizContentRetrieve', 'courseId', courseId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('quizQuizContentRetrieve', 'id', id)
            const localVarPath = `/api/Quiz/{id}/QuizContent/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (courseId !== undefined) {
                localVarQueryParameter['course_id'] = courseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quiz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('quizRetrieve', 'id', id)
            const localVarPath = `/api/Quiz/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuizApi - functional programming interface
 * @export
 */
export const QuizApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuizApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} courseId course_id required for finding submission_expiry
         * @param {number} id A unique integer value identifying this quiz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async quizCourseSpecificDetailRetrieve(courseId: string, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizCourseSpecific>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.quizCourseSpecificDetailRetrieve(courseId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuizApi.quizCourseSpecificDetailRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId all-quizzes for course_id
         * @param {boolean} [hideSubmitted] only return non-submitted quizzes
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async quizCourseSpecificList(courseId: string, hideSubmitted?: boolean, ordering?: string, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuizCourseSpecific>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.quizCourseSpecificList(courseId, hideSubmitted, ordering, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuizApi.quizCourseSpecificList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} [courses] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async quizList(courses?: Array<string>, ordering?: string, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Quiz>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.quizList(courses, ordering, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuizApi.quizList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId course_id required for finding quizcourse
         * @param {number} id A unique integer value identifying this quiz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async quizQuizContentRetrieve(courseId: string, id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.quizQuizContentRetrieve(courseId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuizApi.quizQuizContentRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quiz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async quizRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Quiz>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.quizRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuizApi.quizRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * QuizApi - factory interface
 * @export
 */
export const QuizApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuizApiFp(configuration)
    return {
        /**
         * 
         * @param {string} courseId course_id required for finding submission_expiry
         * @param {number} id A unique integer value identifying this quiz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizCourseSpecificDetailRetrieve(courseId: string, id: number, options?: RawAxiosRequestConfig): AxiosPromise<QuizCourseSpecific> {
            return localVarFp.quizCourseSpecificDetailRetrieve(courseId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId all-quizzes for course_id
         * @param {boolean} [hideSubmitted] only return non-submitted quizzes
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizCourseSpecificList(courseId: string, hideSubmitted?: boolean, ordering?: string, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<QuizCourseSpecific>> {
            return localVarFp.quizCourseSpecificList(courseId, hideSubmitted, ordering, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [courses] 
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizList(courses?: Array<string>, ordering?: string, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<Quiz>> {
            return localVarFp.quizList(courses, ordering, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId course_id required for finding quizcourse
         * @param {number} id A unique integer value identifying this quiz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizQuizContentRetrieve(courseId: string, id: number, options?: RawAxiosRequestConfig): AxiosPromise<QuizContent> {
            return localVarFp.quizQuizContentRetrieve(courseId, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quiz.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizRetrieve(id: number, options?: RawAxiosRequestConfig): AxiosPromise<Quiz> {
            return localVarFp.quizRetrieve(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuizApi - object-oriented interface
 * @export
 * @class QuizApi
 * @extends {BaseAPI}
 */
export class QuizApi extends BaseAPI {
    /**
     * 
     * @param {string} courseId course_id required for finding submission_expiry
     * @param {number} id A unique integer value identifying this quiz.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuizApi
     */
    public quizCourseSpecificDetailRetrieve(courseId: string, id: number, options?: RawAxiosRequestConfig) {
        return QuizApiFp(this.configuration).quizCourseSpecificDetailRetrieve(courseId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId all-quizzes for course_id
     * @param {boolean} [hideSubmitted] only return non-submitted quizzes
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuizApi
     */
    public quizCourseSpecificList(courseId: string, hideSubmitted?: boolean, ordering?: string, search?: string, options?: RawAxiosRequestConfig) {
        return QuizApiFp(this.configuration).quizCourseSpecificList(courseId, hideSubmitted, ordering, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [courses] 
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuizApi
     */
    public quizList(courses?: Array<string>, ordering?: string, search?: string, options?: RawAxiosRequestConfig) {
        return QuizApiFp(this.configuration).quizList(courses, ordering, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId course_id required for finding quizcourse
     * @param {number} id A unique integer value identifying this quiz.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuizApi
     */
    public quizQuizContentRetrieve(courseId: string, id: number, options?: RawAxiosRequestConfig) {
        return QuizApiFp(this.configuration).quizQuizContentRetrieve(courseId, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id A unique integer value identifying this quiz.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuizApi
     */
    public quizRetrieve(id: number, options?: RawAxiosRequestConfig) {
        return QuizApiFp(this.configuration).quizRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * QuizSubmissionApi - axios parameter creator
 * @export
 */
export const QuizSubmissionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} courseId ID of the course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizSubmissionByCourseCourseList: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('quizSubmissionByCourseCourseList', 'courseId', courseId)
            const localVarPath = `/api/QuizSubmission/by-course/course/{course_id}/`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId ID of the course
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizSubmissionByCourseCourseUserList: async (courseId: string, userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('quizSubmissionByCourseCourseUserList', 'courseId', courseId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('quizSubmissionByCourseCourseUserList', 'userId', userId)
            const localVarPath = `/api/QuizSubmission/by-course/course/{course_id}/user/{user_id}/`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [quizCourse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizSubmissionList: async (quizCourse?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/QuizSubmission/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (quizCourse !== undefined) {
                localVarQueryParameter['quiz_course'] = quizCourse;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this quiz submission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizSubmissionRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('quizSubmissionRetrieve', 'id', id)
            const localVarPath = `/api/QuizSubmission/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuizSubmissionApi - functional programming interface
 * @export
 */
export const QuizSubmissionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuizSubmissionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} courseId ID of the course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async quizSubmissionByCourseCourseList(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssessmentQuizList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.quizSubmissionByCourseCourseList(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuizSubmissionApi.quizSubmissionByCourseCourseList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId ID of the course
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async quizSubmissionByCourseCourseUserList(courseId: string, userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssessmentQuizDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.quizSubmissionByCourseCourseUserList(courseId, userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuizSubmissionApi.quizSubmissionByCourseCourseUserList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [quizCourse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async quizSubmissionList(quizCourse?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuizSubmission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.quizSubmissionList(quizCourse, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuizSubmissionApi.quizSubmissionList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this quiz submission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async quizSubmissionRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizSubmission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.quizSubmissionRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuizSubmissionApi.quizSubmissionRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * QuizSubmissionApi - factory interface
 * @export
 */
export const QuizSubmissionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuizSubmissionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} courseId ID of the course
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizSubmissionByCourseCourseList(courseId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AssessmentQuizList>> {
            return localVarFp.quizSubmissionByCourseCourseList(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId ID of the course
         * @param {string} userId ID of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizSubmissionByCourseCourseUserList(courseId: string, userId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AssessmentQuizDetail>> {
            return localVarFp.quizSubmissionByCourseCourseUserList(courseId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [quizCourse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizSubmissionList(quizCourse?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<QuizSubmission>> {
            return localVarFp.quizSubmissionList(quizCourse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this quiz submission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quizSubmissionRetrieve(id: string, options?: RawAxiosRequestConfig): AxiosPromise<QuizSubmission> {
            return localVarFp.quizSubmissionRetrieve(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuizSubmissionApi - object-oriented interface
 * @export
 * @class QuizSubmissionApi
 * @extends {BaseAPI}
 */
export class QuizSubmissionApi extends BaseAPI {
    /**
     * 
     * @param {string} courseId ID of the course
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuizSubmissionApi
     */
    public quizSubmissionByCourseCourseList(courseId: string, options?: RawAxiosRequestConfig) {
        return QuizSubmissionApiFp(this.configuration).quizSubmissionByCourseCourseList(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId ID of the course
     * @param {string} userId ID of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuizSubmissionApi
     */
    public quizSubmissionByCourseCourseUserList(courseId: string, userId: string, options?: RawAxiosRequestConfig) {
        return QuizSubmissionApiFp(this.configuration).quizSubmissionByCourseCourseUserList(courseId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [quizCourse] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuizSubmissionApi
     */
    public quizSubmissionList(quizCourse?: string, options?: RawAxiosRequestConfig) {
        return QuizSubmissionApiFp(this.configuration).quizSubmissionList(quizCourse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this quiz submission.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuizSubmissionApi
     */
    public quizSubmissionRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return QuizSubmissionApiFp(this.configuration).quizSubmissionRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SchemaApi - axios parameter creator
 * @export
 */
export const SchemaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
         * @param {SchemaRetrieveFormatEnum} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schemaRetrieve: async (format?: SchemaRetrieveFormatEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/schema/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SchemaApi - functional programming interface
 * @export
 */
export const SchemaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SchemaApiAxiosParamCreator(configuration)
    return {
        /**
         * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
         * @param {SchemaRetrieveFormatEnum} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async schemaRetrieve(format?: SchemaRetrieveFormatEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.schemaRetrieve(format, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SchemaApi.schemaRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SchemaApi - factory interface
 * @export
 */
export const SchemaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SchemaApiFp(configuration)
    return {
        /**
         * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
         * @param {SchemaRetrieveFormatEnum} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        schemaRetrieve(format?: SchemaRetrieveFormatEnum, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.schemaRetrieve(format, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SchemaApi - object-oriented interface
 * @export
 * @class SchemaApi
 * @extends {BaseAPI}
 */
export class SchemaApi extends BaseAPI {
    /**
     * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     * @param {SchemaRetrieveFormatEnum} [format] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SchemaApi
     */
    public schemaRetrieve(format?: SchemaRetrieveFormatEnum, options?: RawAxiosRequestConfig) {
        return SchemaApiFp(this.configuration).schemaRetrieve(format, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const SchemaRetrieveFormatEnum = {
    Json: 'json',
    Yaml: 'yaml'
} as const;
export type SchemaRetrieveFormatEnum = typeof SchemaRetrieveFormatEnum[keyof typeof SchemaRetrieveFormatEnum];


/**
 * ScoreApi - axios parameter creator
 * @export
 */
export const ScoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Score evaluations with requested testcase.
         * @param {string} courseId 
         * @param {string} testcaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreCreate: async (courseId: string, testcaseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('scoreCreate', 'courseId', courseId)
            // verify required parameter 'testcaseId' is not null or undefined
            assertParamExists('scoreCreate', 'testcaseId', testcaseId)
            const localVarPath = `/api/Score/{testcase_id}/{course_id}`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"testcase_id"}}`, encodeURIComponent(String(testcaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Score evaluations with requested testcase.
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreSubmissionSpecificCreate: async (submissionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('scoreSubmissionSpecificCreate', 'submissionId', submissionId)
            const localVarPath = `/api/Score/{submission_id}/submission_specific/`
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScoreApi - functional programming interface
 * @export
 */
export const ScoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScoreApiAxiosParamCreator(configuration)
    return {
        /**
         * Score evaluations with requested testcase.
         * @param {string} courseId 
         * @param {string} testcaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scoreCreate(courseId: string, testcaseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scoreCreate(courseId, testcaseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScoreApi.scoreCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Score evaluations with requested testcase.
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scoreSubmissionSpecificCreate(submissionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scoreSubmissionSpecificCreate(submissionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScoreApi.scoreSubmissionSpecificCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ScoreApi - factory interface
 * @export
 */
export const ScoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScoreApiFp(configuration)
    return {
        /**
         * Score evaluations with requested testcase.
         * @param {string} courseId 
         * @param {string} testcaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreCreate(courseId: string, testcaseId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.scoreCreate(courseId, testcaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * Score evaluations with requested testcase.
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreSubmissionSpecificCreate(submissionId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.scoreSubmissionSpecificCreate(submissionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScoreApi - object-oriented interface
 * @export
 * @class ScoreApi
 * @extends {BaseAPI}
 */
export class ScoreApi extends BaseAPI {
    /**
     * Score evaluations with requested testcase.
     * @param {string} courseId 
     * @param {string} testcaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoreApi
     */
    public scoreCreate(courseId: string, testcaseId: string, options?: RawAxiosRequestConfig) {
        return ScoreApiFp(this.configuration).scoreCreate(courseId, testcaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Score evaluations with requested testcase.
     * @param {string} submissionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoreApi
     */
    public scoreSubmissionSpecificCreate(submissionId: string, options?: RawAxiosRequestConfig) {
        return ScoreApiFp(this.configuration).scoreSubmissionSpecificCreate(submissionId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StudentListApi - axios parameter creator
 * @export
 */
export const StudentListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * GET: Retrieve a list of students relevant to a course. POST: Add a student by email to a course.
         * @param {string} courseId 
         * @param {Student} student 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentListCreate: async (courseId: string, student: Student, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('studentListCreate', 'courseId', courseId)
            // verify required parameter 'student' is not null or undefined
            assertParamExists('studentListCreate', 'student', student)
            const localVarPath = `/api/StudentList/{course_id}/`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(student, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET: Retrieve a list of students relevant to a course. POST: Add a student by email to a course.
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentListDestroy: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('studentListDestroy', 'courseId', courseId)
            const localVarPath = `/api/StudentList/{course_id}/`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GET: Retrieve a list of students relevant to a course. POST: Add a student by email to a course.
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentListRetrieve: async (courseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('studentListRetrieve', 'courseId', courseId)
            const localVarPath = `/api/StudentList/{course_id}/`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudentListApi - functional programming interface
 * @export
 */
export const StudentListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudentListApiAxiosParamCreator(configuration)
    return {
        /**
         * GET: Retrieve a list of students relevant to a course. POST: Add a student by email to a course.
         * @param {string} courseId 
         * @param {Student} student 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentListCreate(courseId: string, student: Student, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Student>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studentListCreate(courseId, student, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StudentListApi.studentListCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * GET: Retrieve a list of students relevant to a course. POST: Add a student by email to a course.
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentListDestroy(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studentListDestroy(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StudentListApi.studentListDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * GET: Retrieve a list of students relevant to a course. POST: Add a student by email to a course.
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async studentListRetrieve(courseId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Student>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.studentListRetrieve(courseId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StudentListApi.studentListRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StudentListApi - factory interface
 * @export
 */
export const StudentListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudentListApiFp(configuration)
    return {
        /**
         * GET: Retrieve a list of students relevant to a course. POST: Add a student by email to a course.
         * @param {string} courseId 
         * @param {Student} student 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentListCreate(courseId: string, student: Student, options?: RawAxiosRequestConfig): AxiosPromise<Student> {
            return localVarFp.studentListCreate(courseId, student, options).then((request) => request(axios, basePath));
        },
        /**
         * GET: Retrieve a list of students relevant to a course. POST: Add a student by email to a course.
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentListDestroy(courseId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.studentListDestroy(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * GET: Retrieve a list of students relevant to a course. POST: Add a student by email to a course.
         * @param {string} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        studentListRetrieve(courseId: string, options?: RawAxiosRequestConfig): AxiosPromise<Student> {
            return localVarFp.studentListRetrieve(courseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudentListApi - object-oriented interface
 * @export
 * @class StudentListApi
 * @extends {BaseAPI}
 */
export class StudentListApi extends BaseAPI {
    /**
     * GET: Retrieve a list of students relevant to a course. POST: Add a student by email to a course.
     * @param {string} courseId 
     * @param {Student} student 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentListApi
     */
    public studentListCreate(courseId: string, student: Student, options?: RawAxiosRequestConfig) {
        return StudentListApiFp(this.configuration).studentListCreate(courseId, student, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET: Retrieve a list of students relevant to a course. POST: Add a student by email to a course.
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentListApi
     */
    public studentListDestroy(courseId: string, options?: RawAxiosRequestConfig) {
        return StudentListApiFp(this.configuration).studentListDestroy(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GET: Retrieve a list of students relevant to a course. POST: Add a student by email to a course.
     * @param {string} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudentListApi
     */
    public studentListRetrieve(courseId: string, options?: RawAxiosRequestConfig) {
        return StudentListApiFp(this.configuration).studentListRetrieve(courseId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SubmissionApi - axios parameter creator
 * @export
 */
export const SubmissionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} courseId ID of the course
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionByCourseCourseList: async (courseId: string, ordering?: string, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('submissionByCourseCourseList', 'courseId', courseId)
            const localVarPath = `/api/Submission/by-course/course/{course_id}/`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} courseId ID of the course
         * @param {string} userId ID of the user
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionByCourseCourseUserList: async (courseId: string, userId: string, ordering?: string, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('submissionByCourseCourseUserList', 'courseId', courseId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('submissionByCourseCourseUserList', 'userId', userId)
            const localVarPath = `/api/Submission/by-course/course/{course_id}/user/{user_id}/`
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionCsvRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Submission/csv/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} submissionId 
         * @param {string} id 
         * @param {string} url 
         * @param {Date} createdAt 
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionFileSubmissionCreate: async (submissionId: string, id: string, url: string, createdAt: Date, filename?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('submissionFileSubmissionCreate', 'submissionId', submissionId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('submissionFileSubmissionCreate', 'id', id)
            // verify required parameter 'url' is not null or undefined
            assertParamExists('submissionFileSubmissionCreate', 'url', url)
            // verify required parameter 'createdAt' is not null or undefined
            assertParamExists('submissionFileSubmissionCreate', 'createdAt', createdAt)
            const localVarPath = `/api/Submission/{submission_id}/FileSubmission/`
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (id !== undefined) { 
                localVarFormParams.append('id', id as any);
            }
    
            if (filename !== undefined) { 
                localVarFormParams.append('filename', filename as any);
            }
    
            if (url !== undefined) { 
                localVarFormParams.append('url', url as any);
            }
    
            if (createdAt !== undefined) { 
                localVarFormParams.append('created_at', createdAt as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} id 
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionFileSubmissionDestroy: async (id: string, submissionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('submissionFileSubmissionDestroy', 'id', id)
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('submissionFileSubmissionDestroy', 'submissionId', submissionId)
            const localVarPath = `/api/Submission/{submission_id}/FileSubmission/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionFileSubmissionList: async (submissionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('submissionFileSubmissionList', 'submissionId', submissionId)
            const localVarPath = `/api/Submission/{submission_id}/FileSubmission/`
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [isFinal] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionList: async (isFinal?: boolean, limit?: number, offset?: number, ordering?: string, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Submission/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isFinal !== undefined) {
                localVarQueryParameter['is_final'] = isFinal;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (ordering !== undefined) {
                localVarQueryParameter['ordering'] = ordering;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id A UUID string identifying this submission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('submissionRetrieve', 'id', id)
            const localVarPath = `/api/Submission/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Evaluation} evaluation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionScoreCreate: async (evaluation: Evaluation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evaluation' is not null or undefined
            assertParamExists('submissionScoreCreate', 'evaluation', evaluation)
            const localVarPath = `/api/Submission/Score/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(evaluation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionSelectFinalCreate: async (assignmentId: string, courseId: string, submissionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('submissionSelectFinalCreate', 'assignmentId', assignmentId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('submissionSelectFinalCreate', 'courseId', courseId)
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('submissionSelectFinalCreate', 'submissionId', submissionId)
            const localVarPath = `/api/Submission/SelectFinal/{assignment_id}/{course_id}/{submission_id}/`
                .replace(`{${"assignment_id"}}`, encodeURIComponent(String(assignmentId)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionSelectFinalRetrieve: async (assignmentId: string, courseId: string, submissionId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'assignmentId' is not null or undefined
            assertParamExists('submissionSelectFinalRetrieve', 'assignmentId', assignmentId)
            // verify required parameter 'courseId' is not null or undefined
            assertParamExists('submissionSelectFinalRetrieve', 'courseId', courseId)
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('submissionSelectFinalRetrieve', 'submissionId', submissionId)
            const localVarPath = `/api/Submission/SelectFinal/{assignment_id}/{course_id}/{submission_id}/`
                .replace(`{${"assignment_id"}}`, encodeURIComponent(String(assignmentId)))
                .replace(`{${"course_id"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the submissions from users with the ilect-colab library. See /client of this repository to check that library.
         * @param {SubmissionDetailed} submissionDetailed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionSubmitCreate: async (submissionDetailed: SubmissionDetailed, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionDetailed' is not null or undefined
            assertParamExists('submissionSubmitCreate', 'submissionDetailed', submissionDetailed)
            const localVarPath = `/api/Submission/Submit/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submissionDetailed, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the Quiz submissions from users with the ilect-colab library.
         * @param {QuizSubmission} [quizSubmission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionSubmitQuizCreate: async (quizSubmission?: QuizSubmission, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Submission/SubmitQuiz/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(quizSubmission, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubmissionApi - functional programming interface
 * @export
 */
export const SubmissionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubmissionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} courseId ID of the course
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionByCourseCourseList(courseId: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssessmentAssignmentList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionByCourseCourseList(courseId, ordering, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubmissionApi.submissionByCourseCourseList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} courseId ID of the course
         * @param {string} userId ID of the user
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionByCourseCourseUserList(courseId: string, userId: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssessmentAssignmentDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionByCourseCourseUserList(courseId, userId, ordering, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubmissionApi.submissionByCourseCourseUserList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionCsvRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionCsvRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubmissionApi.submissionCsvRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} submissionId 
         * @param {string} id 
         * @param {string} url 
         * @param {Date} createdAt 
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionFileSubmissionCreate(submissionId: string, id: string, url: string, createdAt: Date, filename?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileSubmission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionFileSubmissionCreate(submissionId, id, url, createdAt, filename, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubmissionApi.submissionFileSubmissionCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} id 
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionFileSubmissionDestroy(id: string, submissionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionFileSubmissionDestroy(id, submissionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubmissionApi.submissionFileSubmissionDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionFileSubmissionList(submissionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileSubmission>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionFileSubmissionList(submissionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubmissionApi.submissionFileSubmissionList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [isFinal] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionList(isFinal?: boolean, limit?: number, offset?: number, ordering?: string, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSubmissionListList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionList(isFinal, limit, offset, ordering, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubmissionApi.submissionList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id A UUID string identifying this submission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmissionDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubmissionApi.submissionRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Evaluation} evaluation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionScoreCreate(evaluation: Evaluation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Evaluation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionScoreCreate(evaluation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubmissionApi.submissionScoreCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionSelectFinalCreate(assignmentId: string, courseId: string, submissionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionSelectFinalCreate(assignmentId, courseId, submissionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubmissionApi.submissionSelectFinalCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionSelectFinalRetrieve(assignmentId: string, courseId: string, submissionId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionSelectFinalRetrieve(assignmentId, courseId, submissionId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubmissionApi.submissionSelectFinalRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the submissions from users with the ilect-colab library. See /client of this repository to check that library.
         * @param {SubmissionDetailed} submissionDetailed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionSubmitCreate(submissionDetailed: SubmissionDetailed, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmissionDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionSubmitCreate(submissionDetailed, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubmissionApi.submissionSubmitCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get the Quiz submissions from users with the ilect-colab library.
         * @param {QuizSubmission} [quizSubmission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionSubmitQuizCreate(quizSubmission?: QuizSubmission, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuizSubmission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionSubmitQuizCreate(quizSubmission, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SubmissionApi.submissionSubmitQuizCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SubmissionApi - factory interface
 * @export
 */
export const SubmissionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubmissionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} courseId ID of the course
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionByCourseCourseList(courseId: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AssessmentAssignmentList>> {
            return localVarFp.submissionByCourseCourseList(courseId, ordering, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} courseId ID of the course
         * @param {string} userId ID of the user
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionByCourseCourseUserList(courseId: string, userId: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AssessmentAssignmentDetail>> {
            return localVarFp.submissionByCourseCourseUserList(courseId, userId, ordering, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionCsvRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.submissionCsvRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} submissionId 
         * @param {string} id 
         * @param {string} url 
         * @param {Date} createdAt 
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionFileSubmissionCreate(submissionId: string, id: string, url: string, createdAt: Date, filename?: string, options?: RawAxiosRequestConfig): AxiosPromise<FileSubmission> {
            return localVarFp.submissionFileSubmissionCreate(submissionId, id, url, createdAt, filename, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} id 
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionFileSubmissionDestroy(id: string, submissionId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.submissionFileSubmissionDestroy(id, submissionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionFileSubmissionList(submissionId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<FileSubmission>> {
            return localVarFp.submissionFileSubmissionList(submissionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [isFinal] 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {string} [ordering] Which field to use when ordering the results.
         * @param {string} [search] A search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionList(isFinal?: boolean, limit?: number, offset?: number, ordering?: string, search?: string, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedSubmissionListList> {
            return localVarFp.submissionList(isFinal, limit, offset, ordering, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id A UUID string identifying this submission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionRetrieve(id: string, options?: RawAxiosRequestConfig): AxiosPromise<SubmissionDetailed> {
            return localVarFp.submissionRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Evaluation} evaluation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionScoreCreate(evaluation: Evaluation, options?: RawAxiosRequestConfig): AxiosPromise<Evaluation> {
            return localVarFp.submissionScoreCreate(evaluation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionSelectFinalCreate(assignmentId: string, courseId: string, submissionId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.submissionSelectFinalCreate(assignmentId, courseId, submissionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} assignmentId 
         * @param {string} courseId 
         * @param {string} submissionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionSelectFinalRetrieve(assignmentId: string, courseId: string, submissionId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.submissionSelectFinalRetrieve(assignmentId, courseId, submissionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the submissions from users with the ilect-colab library. See /client of this repository to check that library.
         * @param {SubmissionDetailed} submissionDetailed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionSubmitCreate(submissionDetailed: SubmissionDetailed, options?: RawAxiosRequestConfig): AxiosPromise<SubmissionDetailed> {
            return localVarFp.submissionSubmitCreate(submissionDetailed, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the Quiz submissions from users with the ilect-colab library.
         * @param {QuizSubmission} [quizSubmission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionSubmitQuizCreate(quizSubmission?: QuizSubmission, options?: RawAxiosRequestConfig): AxiosPromise<QuizSubmission> {
            return localVarFp.submissionSubmitQuizCreate(quizSubmission, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubmissionApi - object-oriented interface
 * @export
 * @class SubmissionApi
 * @extends {BaseAPI}
 */
export class SubmissionApi extends BaseAPI {
    /**
     * 
     * @param {string} courseId ID of the course
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public submissionByCourseCourseList(courseId: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).submissionByCourseCourseList(courseId, ordering, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} courseId ID of the course
     * @param {string} userId ID of the user
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public submissionByCourseCourseUserList(courseId: string, userId: string, ordering?: string, search?: string, options?: RawAxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).submissionByCourseCourseUserList(courseId, userId, ordering, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public submissionCsvRetrieve(options?: RawAxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).submissionCsvRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
     * @param {string} submissionId 
     * @param {string} id 
     * @param {string} url 
     * @param {Date} createdAt 
     * @param {string} [filename] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public submissionFileSubmissionCreate(submissionId: string, id: string, url: string, createdAt: Date, filename?: string, options?: RawAxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).submissionFileSubmissionCreate(submissionId, id, url, createdAt, filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
     * @param {string} id 
     * @param {string} submissionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public submissionFileSubmissionDestroy(id: string, submissionId: string, options?: RawAxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).submissionFileSubmissionDestroy(id, submissionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Viewset for file submissions. Note that there is no update mixin. We do not perform update on file submissions.
     * @param {string} submissionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public submissionFileSubmissionList(submissionId: string, options?: RawAxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).submissionFileSubmissionList(submissionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [isFinal] 
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {string} [search] A search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public submissionList(isFinal?: boolean, limit?: number, offset?: number, ordering?: string, search?: string, options?: RawAxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).submissionList(isFinal, limit, offset, ordering, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id A UUID string identifying this submission.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public submissionRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).submissionRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Evaluation} evaluation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public submissionScoreCreate(evaluation: Evaluation, options?: RawAxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).submissionScoreCreate(evaluation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} assignmentId 
     * @param {string} courseId 
     * @param {string} submissionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public submissionSelectFinalCreate(assignmentId: string, courseId: string, submissionId: string, options?: RawAxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).submissionSelectFinalCreate(assignmentId, courseId, submissionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} assignmentId 
     * @param {string} courseId 
     * @param {string} submissionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public submissionSelectFinalRetrieve(assignmentId: string, courseId: string, submissionId: string, options?: RawAxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).submissionSelectFinalRetrieve(assignmentId, courseId, submissionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the submissions from users with the ilect-colab library. See /client of this repository to check that library.
     * @param {SubmissionDetailed} submissionDetailed 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public submissionSubmitCreate(submissionDetailed: SubmissionDetailed, options?: RawAxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).submissionSubmitCreate(submissionDetailed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the Quiz submissions from users with the ilect-colab library.
     * @param {QuizSubmission} [quizSubmission] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public submissionSubmitQuizCreate(quizSubmission?: QuizSubmission, options?: RawAxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).submissionSubmitQuizCreate(quizSubmission, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SyncVimeoVideosApi - axios parameter creator
 * @export
 */
export const SyncVimeoVideosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API view to synchronize Vimeo videos for all courses which has vimeo_folder_id field. This API is triggered from periodically using external service (GCP\'s could scheduler)  Attributes:  Methods:     post(request, *args, **kwargs):         Handles POST requests to synchronize Vimeo videos for all active courses with Vimeo folder IDs.
         * @param {SyncVimeoVideo} [syncVimeoVideo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncVimeoVideosCreate: async (syncVimeoVideo?: SyncVimeoVideo, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/SyncVimeoVideos/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(syncVimeoVideo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SyncVimeoVideosApi - functional programming interface
 * @export
 */
export const SyncVimeoVideosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SyncVimeoVideosApiAxiosParamCreator(configuration)
    return {
        /**
         * API view to synchronize Vimeo videos for all courses which has vimeo_folder_id field. This API is triggered from periodically using external service (GCP\'s could scheduler)  Attributes:  Methods:     post(request, *args, **kwargs):         Handles POST requests to synchronize Vimeo videos for all active courses with Vimeo folder IDs.
         * @param {SyncVimeoVideo} [syncVimeoVideo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncVimeoVideosCreate(syncVimeoVideo?: SyncVimeoVideo, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncVimeoVideo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncVimeoVideosCreate(syncVimeoVideo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SyncVimeoVideosApi.syncVimeoVideosCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SyncVimeoVideosApi - factory interface
 * @export
 */
export const SyncVimeoVideosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SyncVimeoVideosApiFp(configuration)
    return {
        /**
         * API view to synchronize Vimeo videos for all courses which has vimeo_folder_id field. This API is triggered from periodically using external service (GCP\'s could scheduler)  Attributes:  Methods:     post(request, *args, **kwargs):         Handles POST requests to synchronize Vimeo videos for all active courses with Vimeo folder IDs.
         * @param {SyncVimeoVideo} [syncVimeoVideo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncVimeoVideosCreate(syncVimeoVideo?: SyncVimeoVideo, options?: RawAxiosRequestConfig): AxiosPromise<SyncVimeoVideo> {
            return localVarFp.syncVimeoVideosCreate(syncVimeoVideo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SyncVimeoVideosApi - object-oriented interface
 * @export
 * @class SyncVimeoVideosApi
 * @extends {BaseAPI}
 */
export class SyncVimeoVideosApi extends BaseAPI {
    /**
     * API view to synchronize Vimeo videos for all courses which has vimeo_folder_id field. This API is triggered from periodically using external service (GCP\'s could scheduler)  Attributes:  Methods:     post(request, *args, **kwargs):         Handles POST requests to synchronize Vimeo videos for all active courses with Vimeo folder IDs.
     * @param {SyncVimeoVideo} [syncVimeoVideo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncVimeoVideosApi
     */
    public syncVimeoVideosCreate(syncVimeoVideo?: SyncVimeoVideo, options?: RawAxiosRequestConfig) {
        return SyncVimeoVideosApiFp(this.configuration).syncVimeoVideosCreate(syncVimeoVideo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestCaseApi - axios parameter creator
 * @export
 */
export const TestCaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {TestCaseData} testCaseData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testCaseUploadCreate: async (id: string, testCaseData: TestCaseData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testCaseUploadCreate', 'id', id)
            // verify required parameter 'testCaseData' is not null or undefined
            assertParamExists('testCaseUploadCreate', 'testCaseData', testCaseData)
            const localVarPath = `/api/TestCase/Upload/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testCaseData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {TestCaseData} testCaseData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testCaseUploadUpdate: async (id: string, testCaseData: TestCaseData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testCaseUploadUpdate', 'id', id)
            // verify required parameter 'testCaseData' is not null or undefined
            assertParamExists('testCaseUploadUpdate', 'testCaseData', testCaseData)
            const localVarPath = `/api/TestCase/Upload/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testCaseData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestCaseApi - functional programming interface
 * @export
 */
export const TestCaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestCaseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {TestCaseData} testCaseData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testCaseUploadCreate(id: string, testCaseData: TestCaseData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestCaseData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testCaseUploadCreate(id, testCaseData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestCaseApi.testCaseUploadCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {TestCaseData} testCaseData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testCaseUploadUpdate(id: string, testCaseData: TestCaseData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestCaseData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testCaseUploadUpdate(id, testCaseData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestCaseApi.testCaseUploadUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TestCaseApi - factory interface
 * @export
 */
export const TestCaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestCaseApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {TestCaseData} testCaseData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testCaseUploadCreate(id: string, testCaseData: TestCaseData, options?: RawAxiosRequestConfig): AxiosPromise<TestCaseData> {
            return localVarFp.testCaseUploadCreate(id, testCaseData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {TestCaseData} testCaseData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testCaseUploadUpdate(id: string, testCaseData: TestCaseData, options?: RawAxiosRequestConfig): AxiosPromise<TestCaseData> {
            return localVarFp.testCaseUploadUpdate(id, testCaseData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestCaseApi - object-oriented interface
 * @export
 * @class TestCaseApi
 * @extends {BaseAPI}
 */
export class TestCaseApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {TestCaseData} testCaseData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestCaseApi
     */
    public testCaseUploadCreate(id: string, testCaseData: TestCaseData, options?: RawAxiosRequestConfig) {
        return TestCaseApiFp(this.configuration).testCaseUploadCreate(id, testCaseData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {TestCaseData} testCaseData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestCaseApi
     */
    public testCaseUploadUpdate(id: string, testCaseData: TestCaseData, options?: RawAxiosRequestConfig) {
        return TestCaseApiFp(this.configuration).testCaseUploadUpdate(id, testCaseData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TestTypeApi - axios parameter creator
 * @export
 */
export const TestTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Test type which is used in evaluating submissions.
         * @param {TestType} testType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTypeCreate: async (testType: TestType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testType' is not null or undefined
            assertParamExists('testTypeCreate', 'testType', testType)
            const localVarPath = `/api/TestType/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Test type which is used in evaluating submissions.
         * @param {string} id A UUID string identifying this test type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTypeDestroy: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testTypeDestroy', 'id', id)
            const localVarPath = `/api/TestType/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Test type which is used in evaluating submissions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTypeList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/TestType/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Test type which is used in evaluating submissions.
         * @param {string} id A UUID string identifying this test type.
         * @param {PatchedTestType} [patchedTestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTypePartialUpdate: async (id: string, patchedTestType?: PatchedTestType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testTypePartialUpdate', 'id', id)
            const localVarPath = `/api/TestType/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedTestType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Test type which is used in evaluating submissions.
         * @param {string} id A UUID string identifying this test type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTypeRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testTypeRetrieve', 'id', id)
            const localVarPath = `/api/TestType/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Test type which is used in evaluating submissions.
         * @param {string} id A UUID string identifying this test type.
         * @param {TestType} testType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTypeUpdate: async (id: string, testType: TestType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testTypeUpdate', 'id', id)
            // verify required parameter 'testType' is not null or undefined
            assertParamExists('testTypeUpdate', 'testType', testType)
            const localVarPath = `/api/TestType/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(testType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestTypeApi - functional programming interface
 * @export
 */
export const TestTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * Test type which is used in evaluating submissions.
         * @param {TestType} testType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testTypeCreate(testType: TestType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testTypeCreate(testType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestTypeApi.testTypeCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Test type which is used in evaluating submissions.
         * @param {string} id A UUID string identifying this test type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testTypeDestroy(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testTypeDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestTypeApi.testTypeDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Test type which is used in evaluating submissions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testTypeList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TestType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testTypeList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestTypeApi.testTypeList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Test type which is used in evaluating submissions.
         * @param {string} id A UUID string identifying this test type.
         * @param {PatchedTestType} [patchedTestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testTypePartialUpdate(id: string, patchedTestType?: PatchedTestType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testTypePartialUpdate(id, patchedTestType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestTypeApi.testTypePartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Test type which is used in evaluating submissions.
         * @param {string} id A UUID string identifying this test type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testTypeRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testTypeRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestTypeApi.testTypeRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Test type which is used in evaluating submissions.
         * @param {string} id A UUID string identifying this test type.
         * @param {TestType} testType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testTypeUpdate(id: string, testType: TestType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TestType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testTypeUpdate(id, testType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TestTypeApi.testTypeUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TestTypeApi - factory interface
 * @export
 */
export const TestTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestTypeApiFp(configuration)
    return {
        /**
         * Test type which is used in evaluating submissions.
         * @param {TestType} testType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTypeCreate(testType: TestType, options?: RawAxiosRequestConfig): AxiosPromise<TestType> {
            return localVarFp.testTypeCreate(testType, options).then((request) => request(axios, basePath));
        },
        /**
         * Test type which is used in evaluating submissions.
         * @param {string} id A UUID string identifying this test type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTypeDestroy(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.testTypeDestroy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Test type which is used in evaluating submissions.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTypeList(options?: RawAxiosRequestConfig): AxiosPromise<Array<TestType>> {
            return localVarFp.testTypeList(options).then((request) => request(axios, basePath));
        },
        /**
         * Test type which is used in evaluating submissions.
         * @param {string} id A UUID string identifying this test type.
         * @param {PatchedTestType} [patchedTestType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTypePartialUpdate(id: string, patchedTestType?: PatchedTestType, options?: RawAxiosRequestConfig): AxiosPromise<TestType> {
            return localVarFp.testTypePartialUpdate(id, patchedTestType, options).then((request) => request(axios, basePath));
        },
        /**
         * Test type which is used in evaluating submissions.
         * @param {string} id A UUID string identifying this test type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTypeRetrieve(id: string, options?: RawAxiosRequestConfig): AxiosPromise<TestType> {
            return localVarFp.testTypeRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Test type which is used in evaluating submissions.
         * @param {string} id A UUID string identifying this test type.
         * @param {TestType} testType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testTypeUpdate(id: string, testType: TestType, options?: RawAxiosRequestConfig): AxiosPromise<TestType> {
            return localVarFp.testTypeUpdate(id, testType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestTypeApi - object-oriented interface
 * @export
 * @class TestTypeApi
 * @extends {BaseAPI}
 */
export class TestTypeApi extends BaseAPI {
    /**
     * Test type which is used in evaluating submissions.
     * @param {TestType} testType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTypeApi
     */
    public testTypeCreate(testType: TestType, options?: RawAxiosRequestConfig) {
        return TestTypeApiFp(this.configuration).testTypeCreate(testType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Test type which is used in evaluating submissions.
     * @param {string} id A UUID string identifying this test type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTypeApi
     */
    public testTypeDestroy(id: string, options?: RawAxiosRequestConfig) {
        return TestTypeApiFp(this.configuration).testTypeDestroy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Test type which is used in evaluating submissions.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTypeApi
     */
    public testTypeList(options?: RawAxiosRequestConfig) {
        return TestTypeApiFp(this.configuration).testTypeList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Test type which is used in evaluating submissions.
     * @param {string} id A UUID string identifying this test type.
     * @param {PatchedTestType} [patchedTestType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTypeApi
     */
    public testTypePartialUpdate(id: string, patchedTestType?: PatchedTestType, options?: RawAxiosRequestConfig) {
        return TestTypeApiFp(this.configuration).testTypePartialUpdate(id, patchedTestType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Test type which is used in evaluating submissions.
     * @param {string} id A UUID string identifying this test type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTypeApi
     */
    public testTypeRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return TestTypeApiFp(this.configuration).testTypeRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Test type which is used in evaluating submissions.
     * @param {string} id A UUID string identifying this test type.
     * @param {TestType} testType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestTypeApi
     */
    public testTypeUpdate(id: string, testType: TestType, options?: RawAxiosRequestConfig) {
        return TestTypeApiFp(this.configuration).testTypeUpdate(id, testType, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VimeoVideosApi - axios parameter creator
 * @export
 */
export const VimeoVideosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves all Vimeo videos for courses, with filters.  Requires admin, or student or editor group permission.
         * @param {string} [course] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vimeoVideosList: async (course?: string, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/vimeo-videos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (course !== undefined) {
                localVarQueryParameter['course'] = course;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a single VimeoVideo object, only with name field.
         * @param {string} videoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vimeoVideosNameRetrieve: async (videoId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'videoId' is not null or undefined
            assertParamExists('vimeoVideosNameRetrieve', 'videoId', videoId)
            const localVarPath = `/api/vimeo-videos/{video_id}/name`
                .replace(`{${"video_id"}}`, encodeURIComponent(String(videoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VimeoVideosApi - functional programming interface
 * @export
 */
export const VimeoVideosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VimeoVideosApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves all Vimeo videos for courses, with filters.  Requires admin, or student or editor group permission.
         * @param {string} [course] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vimeoVideosList(course?: string, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VimeoVideo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vimeoVideosList(course, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VimeoVideosApi.vimeoVideosList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a single VimeoVideo object, only with name field.
         * @param {string} videoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vimeoVideosNameRetrieve(videoId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VimeoVideoNameOnly>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vimeoVideosNameRetrieve(videoId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VimeoVideosApi.vimeoVideosNameRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VimeoVideosApi - factory interface
 * @export
 */
export const VimeoVideosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VimeoVideosApiFp(configuration)
    return {
        /**
         * Retrieves all Vimeo videos for courses, with filters.  Requires admin, or student or editor group permission.
         * @param {string} [course] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vimeoVideosList(course?: string, name?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<VimeoVideo>> {
            return localVarFp.vimeoVideosList(course, name, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a single VimeoVideo object, only with name field.
         * @param {string} videoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vimeoVideosNameRetrieve(videoId: string, options?: RawAxiosRequestConfig): AxiosPromise<VimeoVideoNameOnly> {
            return localVarFp.vimeoVideosNameRetrieve(videoId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VimeoVideosApi - object-oriented interface
 * @export
 * @class VimeoVideosApi
 * @extends {BaseAPI}
 */
export class VimeoVideosApi extends BaseAPI {
    /**
     * Retrieves all Vimeo videos for courses, with filters.  Requires admin, or student or editor group permission.
     * @param {string} [course] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VimeoVideosApi
     */
    public vimeoVideosList(course?: string, name?: string, options?: RawAxiosRequestConfig) {
        return VimeoVideosApiFp(this.configuration).vimeoVideosList(course, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a single VimeoVideo object, only with name field.
     * @param {string} videoId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VimeoVideosApi
     */
    public vimeoVideosNameRetrieve(videoId: string, options?: RawAxiosRequestConfig) {
        return VimeoVideosApiFp(this.configuration).vimeoVideosNameRetrieve(videoId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WorkbookConfigApi - axios parameter creator
 * @export
 */
export const WorkbookConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workbookConfigList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkbookConfig/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a WorkbookConfig instance, and user\'s workbook usage on current month.
         * @param {string} id A UUID string identifying this workbook config.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workbookConfigRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('workbookConfigRetrieve', 'id', id)
            const localVarPath = `/api/WorkbookConfig/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkbookConfigApi - functional programming interface
 * @export
 */
export const WorkbookConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkbookConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workbookConfigList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkbookConfigList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workbookConfigList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkbookConfigApi.workbookConfigList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns a WorkbookConfig instance, and user\'s workbook usage on current month.
         * @param {string} id A UUID string identifying this workbook config.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workbookConfigRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkbookConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workbookConfigRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkbookConfigApi.workbookConfigRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WorkbookConfigApi - factory interface
 * @export
 */
export const WorkbookConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkbookConfigApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workbookConfigList(options?: RawAxiosRequestConfig): AxiosPromise<Array<WorkbookConfigList>> {
            return localVarFp.workbookConfigList(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a WorkbookConfig instance, and user\'s workbook usage on current month.
         * @param {string} id A UUID string identifying this workbook config.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workbookConfigRetrieve(id: string, options?: RawAxiosRequestConfig): AxiosPromise<WorkbookConfig> {
            return localVarFp.workbookConfigRetrieve(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkbookConfigApi - object-oriented interface
 * @export
 * @class WorkbookConfigApi
 * @extends {BaseAPI}
 */
export class WorkbookConfigApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkbookConfigApi
     */
    public workbookConfigList(options?: RawAxiosRequestConfig) {
        return WorkbookConfigApiFp(this.configuration).workbookConfigList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a WorkbookConfig instance, and user\'s workbook usage on current month.
     * @param {string} id A UUID string identifying this workbook config.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkbookConfigApi
     */
    public workbookConfigRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return WorkbookConfigApiFp(this.configuration).workbookConfigRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }
}



