<template>
  <q-form class="q-gutter-md" @submit="onEmailLogin()">
    <div class="q-px-md">
      <q-input
        v-model="registration.email"
        outlined
        type="email"
        :label="$t('views.signin.email')"
        class="q-pb-md"
      >
        <template #prepend>
          <q-icon name="email" />
        </template>
      </q-input>
      <q-input
        v-model="registration.password1"
        outlined
        :type="passwordShow ? 'text' : 'password'"
        :label="$t('views.signin.password')"
        bottom-slots
        class="q-pb-xs"
      >
        <template #append>
          <q-icon
            :name="passwordShow ? 'visibility' : 'visibility_off'"
            class="cursor-pointer"
            @click="passwordShow = !passwordShow"
          />
        </template>
        <template #prepend>
          <q-icon name="fas fa-key" size="s" />
        </template>
      </q-input>
      <q-btn
        rounded
        style="float: right"
        :label="$t('common.signin')"
        type="submit"
        color="text1"
        class="q-mt-sm"
      />
      <q-btn
        no-caps
        flat
        unelevated
        style="float: left; top;"
        :label="$t('views.signin.password_reset_button')"
        class="btn--no-hover q-pl-xs"
        size="md"
        @click="showPasswordResetPopup"
      />
    </div>
  </q-form>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { Login, Register } from "@/openapi-ts";
import { Notify } from "quasar";
import { AuthModule } from "@/store/modules/auth";
import { useRouter } from "vue-router";
import axios from "axios";
import { useQuasar } from "quasar";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup(props) {
    const $q = useQuasar();
    const $t = useI18n().t;

    const login = ref<Login>({ email: "", password: "" });
    // Django allauth api does not require username, but openapi says otherwise. Live with it.
    const registration = ref<Register>({
      email: "",
      password1: "",
      password2: "",
      username: "aaa",
    });
    const passwordShow = ref(false);

    const $router = useRouter();

    const apiBasePath = import.meta.env.VITE_APP_BACKEND_API_ENDPOINT;

    async function onEmailLogin() {
      try {
        login.value.password = registration.value.password1;
        login.value.email = registration.value.email;

        const uninterruptedAxios = axios.create();
        const response = await uninterruptedAxios.post(
          `${apiBasePath}/api/Auth/Token/`,
          login.value,
        );

        await AuthModule.registerWithEmail(response.data);
        await AuthModule.postLogin();

        await $router.push({ name: "Course", replace: true });
      } catch {
        Notify.create({
          type: "negative",
          message: "Email authentication failed.",
        });
      }
    }

    function showPasswordResetPopup() {
      $q.dialog({
        message: $t("views.signin.password_reset_message"),
        persistent: true,
        ok: "OK",
        cancel: false,
        class: "max-width: 400px; border-radius: 8px;",
      });
    }

    return {
      passwordShow,
      registration,
      onEmailLogin,
      props,
      showPasswordResetPopup,
    };
  },
});
</script>

<style scoped>
:deep(.q-btn.btn--no-hover .q-focus-helper) {
  display: none;
}

.q-btn {
  color: grey;
}
.q-btn:hover {
  color: rgba(57, 163, 223, 1); /* Text color on hover */
}
</style>
